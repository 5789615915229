import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { RedWarningTriangleIcon } from '@motorway/motorway-storybook-cra';

import { VRM_CHECK_CODES } from 'Utilities/vrm';

import Button from 'Button';

import LocalTexts from './HomeVRMErrorsTexts.json';

import styles from './HomeVRMErrors.less';

const LocalT = new MDText(LocalTexts);

const HomeVRMErrors = ({
  error,
}) => {
  if (!error) {
    return (null);
  }

  const { error: errorMsg, vrm } = error;

  let title;
  let detail;
  let additional;

  const refreshButton = (
    <Button
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        window.location.reload();
      }}
    >
      { LocalT.translate('refresh') }
    </Button>
  );

  switch (errorMsg) {
    case VRM_CHECK_CODES.EXCEEDED: {
      title = LocalT.translate('exceeded.title');
      detail = LocalT.translate('exceeded.detail');
      additional = (
        <Button
          className={cx(styles.button, 'mw-button-large')}
          element={Link}
          rightIcon="chevron-right"
          to="/account"
        >
          { LocalT.translate('goToAccount') }
        </Button>
      );

      break;
    }
    case VRM_CHECK_CODES.INVALID_VRM: {
      title = LocalT.translate('invalid_vrm.title', { vrm });
      detail = LocalT.translate('invalid_vrm.detail');

      break;
    }
    case VRM_CHECK_CODES.TOO_MANY_REQUESTS: {
      title = LocalT.translate('too_many_requests.title');
      detail = LocalT.translate('too_many_requests.detail');

      break;
    }
    case VRM_CHECK_CODES.VEHICLE_GENERATION_FAILURE: {
      title = LocalT.translate('vehicle_generation_failure.title');
      detail = LocalT.translate('vehicle_generation_failure.detail');

      additional = refreshButton;

      break;
    }
    case VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED: {
      title = LocalT.translate('vehicle_lookup_failed.title');
      detail = LocalT.translate('vehicle_lookup_failed.detail');

      break;
    }
    default: {
      title = LocalT.translate('default.title');
      detail = LocalT.translate('default.detail');

      additional = refreshButton;

      break;
    }
  }

  return (
    <div className={cx(styles.component, styles[`error_${errorMsg}`])}>
      <RedWarningTriangleIcon className={styles.icon} />
      <h2>{ title }</h2>
      <p>{ detail }</p>
      { additional }
    </div>
  );
};

HomeVRMErrors.defaultProps = {
  error: null,
};

HomeVRMErrors.propTypes = {
  error: PropTypes.shape({
    error: PropTypes.string.isRequired,
    vrm: PropTypes.string.isRequired,
  }),
};

export default HomeVRMErrors;
