import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Link, Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { VRM_CHECK_CODES } from 'Utilities/vrm';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import Lock from '../lock/Lock';
import TrustPilotImage from '../TrustPilotImage/TrustPilotImage';
import VrmInput from '../vrmInput/VrmInput';

import HomeVRMErrors from './homeVRMErrors/HomeVRMErrors';
import HomeVehicle from './HomeVehicle';
import HomeVRMBrand from './HomeVRMBrand';
import LocalTexts from './HomeVRMTexts.json';

import styles from './HomeVRM.scss';

const LocalT = new MDText(LocalTexts);

const HomeVRM = ({
  analyticsEvents,
  inProgress,
  loadingHandler,
  subTitle,
  title,
}) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);

  const hasError = vehicle?.resultError;

  const history = useHistory();

  const [showMileage, setShowMileage] = useState(history.location.pathname.endsWith('/mileage'));

  const mileageRegex = /\/mileage\/?$/;

  useEffect(() => {
    // Fail safe to ensure you can't end up on mileage without a vehicle
    if (!vehicle?.id) {
      setShowMileage(false);
      history.replace({
        ...history.location,
        pathname: history.location.pathname.replace(mileageRegex, '') || '/',
      });
    }
    const unlisten = history.listen(({ pathname }) => {
      // If someone clicks `Not my car` it resets the store,
      // so need to make sure if no vehicle, no mileage page!
      setShowMileage(vehicle?.id ? pathname.endsWith('/mileage') : false);

      // reset url to '/' if `Not my car` is clicked and then browser back is clicked
      if (pathname.endsWith('/mileage') && !vehicle?.id) {
        history.replace({
          ...history.location,
          pathname: history.location.pathname.replace(mileageRegex, '') || '/',
        });
      }
    });

    return () => unlisten();
  }, [history, vehicle?.id]);

  useEffect(() => {
    // Fail safe to ensure you can't end up on mileage without a vehicle
    if (!vehicle?.id) {
      setShowMileage(false);
    }
  }, [vehicle?.id]);

  useEffect(() => {
    if (hasError) {
      VALUATIONS_GA_TAGS.VEHICLE_LOOKUP_FAILED(hasError);
      VALUATIONS_GA_TAGS.HERO_VEHICLE_LOOKUP_FAILED(hasError);
    }
  }, [hasError]);

  const straplineCopy = (<ComponentContent maxWidth={ CONTENT_SIZES.large } paddings={ true }>
      <h2 className={ styles.heroStrap }>
        { subTitle }
      </h2>
    </ComponentContent>);

  const strapline = (showMileage)
    ? (
      <HomeVehicle {...{ vehicle }} />
    )
    : (
      <>
        <Switch>
          <Route exact component={HomeVRMBrand} path="/sell-my-car/brands/:brand" />
          <Route exact path={ ['/', '/mileage'] }>
            <>
              {/* TODO: Replace with animated header after redesign - Grant Hynd */}
              <h1 className={styles.svgHeroTitle}>
                The way to
                {' '}
                <div className={styles.second}>
                  <span className={styles.secondTag}>sell your car</span>
                </div>
              </h1>
              <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 550, padding: '0 24px 24px 24px' }}>
                <svg viewBox="0 0 362 34" xmlns="http://www.w3.org/2000/svg">
                  <text
                    dominantBaseline="hanging"
                    fontFamily="NewTransportSemi, Helvetica, Arial, sans-serif"
                    fontSize="34">
                    The way to sell your car
                  </text>
                </svg>
              </div>
              { straplineCopy }
            </>
          </Route>
          <Route>
            <>
              <ComponentContent maxWidth={CONTENT_SIZES.content} paddings={true}>
                <h1 className={styles.heroTitle}>{title}</h1>
              </ComponentContent>
              { straplineCopy }
            </>
          </Route>
        </Switch>
      </>
    );

  const setMileageUrl = (value) => {
    let newPathname;
    const { pathname } = history.location;

    if (value) {
      newPathname = pathname === '/' ? '/mileage' : `${pathname}/mileage`;
    } else {
      newPathname = pathname.replace(mileageRegex, '');
    }

    history.push(
      history.createHref({
        ...history.location,
        pathname: newPathname,
      }),
    );
  };

  return (
    <Lock lock={inProgress}>
      <ComponentContent
        className={cx(styles.component, { [styles.hasVehicle]: showMileage })}
        maxWidth={CONTENT_SIZES.full}
      >
        { (hasError) ? (
          <ComponentContent maxWidth={CONTENT_SIZES.large} paddings={false}>
            <HomeVRMErrors
              error={{
                error: vehicle?.resultError?.msg,
                vrm: vehicle?.resultError?.vrm,
              }}
            />
          </ComponentContent>
        ) : (
          <>{ strapline }</>
        )}
      </ComponentContent>
      {
        (![
          VRM_CHECK_CODES.BAD_REQUEST,
          VRM_CHECK_CODES.EXCEEDED,
          VRM_CHECK_CODES.TOO_MANY_REQUESTS,
          VRM_CHECK_CODES.VEHICLE_GENERATION_FAILURE,
        ].includes(hasError?.msg)) ? (
          <>
            <VrmInput
              setShowMileage={setMileageUrl}
              {...{
                analyticsEvents,
                inProgress,
                loadingHandler,
                showMileage,
                vehicle,
              }}
            />
            {
              ((!hasError && !showMileage)) ? (
                <ComponentContent
                  maxWidth={CONTENT_SIZES.large}
                >
                  <Link to="/reviews">
                    <TrustPilotImage />
                  </Link>
                </ComponentContent>
              ) : (null)
            }
          </>
          ) : (null)
      }
    </Lock>
  );
};

HomeVRM.defaultProps = {
  analyticsEvents: {},
  inProgress: false,
  loadingHandler: () => {},
  showMileage: false,
  subTitle: (<><span>5,000+ dealers compete to give you</span> <span>their best price*. Find your highest offer!</span></>) || LocalT.translate('hero.home.strapline'),
  title: LocalT.translate('hero.home.title'),
};

HomeVRM.propTypes = {
  analyticsEvents: PropTypes.shape(),
  inProgress: PropTypes.bool,
  loadingHandler: PropTypes.func,
  showMileage: PropTypes.bool,
  subTitle: PropTypes.node,
  title: PropTypes.node,
};

export default HomeVRM;
