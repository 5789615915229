import React from 'react';
import PropTypes from 'prop-types';

import styles from './HighlightWord.less';

const escapeRegExp = (arr = []) => (
  arr.map((str) => `(${str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')})`)
);

const HighlightWord = ({ children, words }) => {
  const reg = new RegExp(escapeRegExp(words).join('|'), 'i');
  const parts = String(children).split(reg);

  if (words?.length) {
    return parts.map((part, index) => (
      // eslint-disable-next-line react/no-array-index-key
      reg.test(part) ? <mark key={index} className={styles.highlight}>{part}</mark> : part
    ));
  }
  return children;
};

HighlightWord.defaultProps = {
  children: null,
  words: null,
};

HighlightWord.propTypes = {
  children: PropTypes.node,
  words: PropTypes.arrayOf(PropTypes.string),
};

export default HighlightWord;
