import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ThemeDark.less';

const ThemeDark = (props) => {
  const {
    children, className, theme, ...p
  } = props; // eslint-disable-line no-unused-vars

  return (
    <div className={cx('mw-parent-theme-dark', styles.theme, className)} {...p}>
      {children}
    </div>
  );
};

ThemeDark.defaultProps = {
  children: [],
  className: null,
  theme: null,
};

ThemeDark.propTypes = {
  children: PropTypes.shape(),
  className: PropTypes.func,
  theme: PropTypes.func,
};

export default ThemeDark;
