import React, {
  forwardRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { classNamePropType } from 'Utilities/propTypes';

import styles from './SvgAnimationBox.less';

const animationStartTime = 700;

const SvgAnimationBox = forwardRef(({
  animate, className, elem: Elem, hiddenClassName, id, repeatTime, showSvg, startTime, svg: Svg,
}, ref) => {
  const [start, setStart] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setStart(animate);
    let timeOut;
    if (animate && loaded) {
      // Starting animation by click, due to Chrome behavior.
      // Chrome do not trigger animation start when you come back to this animation on second, third ... time.
      timeOut = setTimeout(() => {
        document.getElementById(id)?.dispatchEvent?.(new Event('click'));
      }, animationStartTime);
    }
    return () => clearTimeout(timeOut);
  }, [animate, id, loaded]);

  return (
    <Elem ref={ref} className={cx(styles.box, className, { [styles.loading]: !loaded, [hiddenClassName]: !loaded })}>
      { showSvg && (
        <Svg
          activate={start}
          animationId={id}
          onLoad={setLoaded}
          repeatTime={repeatTime}
          startTime={startTime}
        />
      )}
    </Elem>
  );
});

SvgAnimationBox.defaultProps = {
  animate: false,
  className: '',
  elem: 'div',
  hiddenClassName: null,
  id: '',
  repeatTime: undefined,
  showSvg: true,
  startTime: undefined,
  svg: null,
};

SvgAnimationBox.propTypes = {
  animate: PropTypes.bool,
  className: classNamePropType,
  elem: PropTypes.string,
  hiddenClassName: classNamePropType,
  id: PropTypes.string,
  repeatTime: PropTypes.string,
  showSvg: PropTypes.bool,
  startTime: PropTypes.string,
  svg: PropTypes.elementType,
};

export default SvgAnimationBox;
