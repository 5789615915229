import React, { useContext, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import cx from 'classnames';

import T, { MDText } from 'i18n-react';

import { FeedbackTooltip, NumberFormatInput, Tooltip, TooltipAnchor } from '@motorway/motorway-storybook-cra';
import { InputNumericFormat } from '@motorway/mw-highway-code/alpha';
import { SIZE } from '@motorway/mw-highway-code/enums';

import cypressIds from 'CypressId';
import Texts from 'CypressText';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { stringNumberToInt } from 'Utilities/formatter';
import {
  checkMotMileage,
  composeValidators,
  getFieldState,
  INTENT,
  numberCommaSeperatedValidator,
} from 'Utilities/formValidators';
import { applyCypressData } from 'Utilities/index';
import { getMileage } from 'Utilities/mileage';
import { classNamePropType } from 'Utilities/propTypes';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';
import useShowNewHomepage from '../../homepage/Hooks/useShowNewHomepage';

import LocalTexts from './MileageInputTexts.json';

import styles from './MileageInput.scss';

const LocalT = new MDText(LocalTexts);
T.setTexts(Texts);

const t = (key, args) => `${LocalT.translate(key, args)}`;

const texts = {
  mileageChecker: {
    expired: t('mileageChecker.expiredLabel'),
    label: t('mileageChecker.label'),
    populated: t('mileageChecker.populatedLabel'),
  },
  mileageField: {
    placeholder: t('mileageField.placeholder'),
  },
};

const getInputLabel = (isPadded, mileageValue) => {
  const { mileageChecker: { expired, label, populated } } = texts;
  if (isPadded) {
    return expired;
  }
  if (parseFloat(mileageValue) > 0) {
    return populated;
  }
  return label;
};

const mileageInputAttributes = {
  ...applyCypressData(cypressIds.fields.mileageInput),
  autoComplete: 'off',
  autoCorrect: 'off',
  maxLength: 7,
};

const renderTHCInputMileageField = (
  { analyticsEvents, inputLabel, isHero, isMobile, onInputErrorState, padded, showNewHomepage, validateOnInit },
  { id, input, meta },
) => (
  <FeedbackTooltip
    appendTo={'parent'}
    className={cx(styles.tooltip, styles.bespokeTooltip, {
      [styles.hidden]: !meta.error,
    })}
    content={meta.error}
    intent={getFieldState(meta, validateOnInit && input.value)}
    maxWidth={isMobile ? 300 : 250}
    placement={isMobile || showNewHomepage ? 'top' : 'bottom'}
  >
    <InputNumericFormat
      fullWidth
      formProps={{
        input: {
          ...input,
          ...mileageInputAttributes,
          onFocus: (e) => {
            input.onFocus(e);
            if (isHero) {
              VALUATIONS_GA_TAGS.HERO_MILEAGE_INPUT_FOCUSED();
            }
            analyticsEvents?.onInputFocus?.();
          },
          placeholder: texts.mileageField.placeholder,
        },
      }}
      id={id}
      intent={(() => {
        const state = getFieldState(meta, validateOnInit && input.value);
        if (state === INTENT.ERROR) {
          onInputErrorState(input.value);
        }
        return state;
      })()}
      label={inputLabel}
      showLabel={false}
      size={padded ? SIZE.SMALL : SIZE.LARGE}
    />
  </FeedbackTooltip>
);

const renderMileageField = (
  { analyticsEvents, isHero, onInputErrorState, padded, validateOnInit },
  { id, input, meta },
) => (
  <NumberFormatInput
    className={styles.input}
    feedbackTooltip={{
      content: meta.error,
      placement: 'top',
    }}
    inputProps={{
      id,
      input: {
        ...input,
        ...mileageInputAttributes,
        'aria-label': 'mileage',
        inputMode: 'numeric',
        onFocus: (e) => {
          input.onFocus(e);
          if (isHero) {
            VALUATIONS_GA_TAGS.HERO_MILEAGE_INPUT_FOCUSED();
          }
          analyticsEvents?.onInputFocus?.();
        },
        pattern: '[0-9]+(,[0-9]+)*',
        placeholder: t('mileageChecker.placeholder'),
        required: true,
        type: 'text',
      },
    }}
    intent={(() => {
      const state = getFieldState(meta, validateOnInit && input.value);

      if (state === INTENT.ERROR) {
        onInputErrorState(input.value);
      }

      return state;
    })()}
    large={!padded}
  />
);

const MileageInput = ({
  analyticsEvents,
  className,
  isCarValueTracker,
  isHero,
  isHighMileageModal,
  isShowInfoTooltip,
  isUseTHCInput,
  mileage: initialMileage,
  noLabel,
  padded,
  titleClassName,
  validateOnInit,
}) => {
  const { maxWidth } = useBreakpoint();

  const { vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);

  const { showNewHomepage } = useShowNewHomepage();

  const { pristine, values } = useFormState();
  const [mileageValue] = useState(
    stringNumberToInt(initialMileage) || getMileage({ vehicle: lookupVehicle }) || '',
  );
  if (pristine) {
    values.mileage = mileageValue;
  }

  const isMobile = maxWidth.breakpointMobile;
  const inputLabel = getInputLabel(padded, mileageValue);

  const mileageLabelWithInfoTooltip = (!noLabel && isShowInfoTooltip) && (
    <div className={styles.fieldset}>
      <span className={cx(styles.title, titleClassName, { [styles.titleSmall]: padded })}>
        {inputLabel}
      </span>
      {
        ((mileageValue === lookupVehicle?.mileageEst)) ? (
          <Tooltip
            overlay={<div>{LocalT.translate('mileageChecker.tooltip.detail')}</div>}
          >
            <span>
              {padded && <span className={cx('mw-link mw-link-blue', styles.tooltipLabel)}>{LocalT.translate('mileageChecker.tooltip.label')}</span>}
              <TooltipAnchor background onMouseEnter={() => {
                VALUATIONS_GA_TAGS.HERO_ESTIMATED_MILEAGE_TOOLTIP_SHOWN();
              }} />
            </span>
          </Tooltip>
        ) : (null)
      }
    </div>
  );

  const onInputErrorState = (value) => {
    if (isCarValueTracker) {
      return;
    }

    const analyticsEvent = isHighMileageModal
      ? VALUATIONS_GA_TAGS.MILEAGE_MODAL_ERROR_TOOLTIP
      : VALUATIONS_GA_TAGS.MILEAGE_ERROR_TOOLTIP;

    analyticsEvent({ mileage: value });
  };

  return (
    <div className={cx(styles.component, className, { [styles.padded]: padded })}>
      {mileageLabelWithInfoTooltip}

      <Field
        id="mileage-input"
        name="mileage"
        // https://codesandbox.io/s/10rzowm323?file=/index.js
        parse={(val) => stringNumberToInt(val) || ''}
        validate={composeValidators(
          numberCommaSeperatedValidator(LocalT.translate('mileageChecker.error.errorMessage')),
          checkMotMileage(t('mileageChecker.error.tooLow'), lookupVehicle?.mileageLastMot),
        )}
      >
        {({ id, input, meta }) => (
          isUseTHCInput
            ? renderTHCInputMileageField(
              {
                analyticsEvents,
                inputLabel,
                isHero,
                isMobile,
                onInputErrorState,
                padded,
                showNewHomepage,
                validateOnInit,
              },
              { id, input, meta },
            )
            : renderMileageField(
              { analyticsEvents, isHero, onInputErrorState, padded, validateOnInit },
              { id, input, meta },
            )
        )}
      </Field>
    </div>
  );
};

MileageInput.defaultProps = {
  analyticsEvents: undefined,
  className: null,
  isCarValueTracker: false,
  isHero: false,
  isShowInfoTooltip: true,
  isUseTHCInput: false,
  mileage: null,
  noLabel: false,
  padded: false,
  titleClassName: null,
  validateOnInit: null,
};

MileageInput.propTypes = {
  analyticsEvents: PropTypes.shape({
    onButtonClick: PropTypes.func,
    onInputFocus: PropTypes.func,
  }),
  className: classNamePropType,
  isCarValueTracker: PropTypes.bool,
  isHero: PropTypes.bool,
  isHighMileageModal: PropTypes.bool,
  isShowInfoTooltip: PropTypes.bool,
  isUseTHCInput: PropTypes.bool,
  mileage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  noLabel: PropTypes.bool,
  padded: PropTypes.bool,
  titleClassName: classNamePropType,
  validateOnInit: PropTypes.bool,
};

export default MileageInput;
