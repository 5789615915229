import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';
import { INSTANT_MATCH_URL, MOTORWAY_URL } from 'Utilities/urls';
import {
  BLOG_PATH,
  FULL_LANDING_PAGE_ROUTES,
  GUIDES_PATH,
  SELL_MY_CAR_PATH,
  ULEZ_PAGE_ROUTES,
} from 'Utilities/wordpress';

const categories = ['blog', 'guides', 'homepage', 'landing_page', 'ulez', 'other'];

const STICKY_CATEGORY = categories.reduce((acc: any, val) => {
  acc[val.toUpperCase()] = val;
  return acc;
}, {});

export const getCategoryForSticky = (pathname: string) => {
  switch (true) {
    case (pathname.includes(BLOG_PATH)):
      return STICKY_CATEGORY.BLOG;
    case (pathname.includes(GUIDES_PATH) || (pathname === '/feature/guides')):
      return STICKY_CATEGORY.GUIDES;
    case (pathname === ULEZ_PAGE_ROUTES[0]):
      return STICKY_CATEGORY.ULEZ;
    case (FULL_LANDING_PAGE_ROUTES.includes(pathname)):
      return STICKY_CATEGORY.LANDING_PAGE;
    case (pathname === '/'):
      return STICKY_CATEGORY.HOMEPAGE;
    default:
      return STICKY_CATEGORY.OTHER;
  }
};

type StickyVrmVersion = 'new' | 'old';
type StickyVrmLCategory = 'blog' | 'guides' | 'homepage' | 'landing_page' | 'ulez' | 'other';

type StickyClickProps = {
  category: StickyVrmLCategory;
  label?: StickyVrmVersion;
  name?: string;
  url?: string | null;
};

type StickyCtaProps = {
  category: StickyVrmLCategory;
  label?: string | null;
  url?: string | null;
};

type StickyLoadedProps = {
  category: StickyVrmLCategory;
  label: 'new_input' | 'new_no_input' | 'old';
};

type StickyWidgetLoadedProps = {
  category: StickyVrmLCategory;
  label?: StickyVrmVersion;
};

export const STICKY_VRM_EVENTS = {

  STICKY_IM_WIDGET_CTA_CLICK: ({ category, url } : StickyCtaProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label: null,
      name: 'browse_all_cars',
      url,
    });
  },

  STICKY_VRM_BANNER_BUY_CLICK: ({ category, label = 'new' } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'sticky_buy',
      url: INSTANT_MATCH_URL,
    });
  },

  // Sticky Banner events
  STICKY_VRM_BANNER_CTA_CLICK: ({ category, label = null, url = null } : StickyCtaProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'sticky_sell_car',
      url,
    });
  },

  STICKY_VRM_BANNER_INPUT_FOCUS: ({ category, label = 'new', name = 'sticky_vrm_input', url = null } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name,
      ...(url && { url }),
    });
  },

  STICKY_VRM_BANNER_LOADED: ({ category, label } : StickyLoadedProps) => {
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category,
      label,
      name: 'sticky',
    });
  },

  STICKY_VRM_BANNER_LOGO_CLICK: ({ category, label = 'new' } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'sticky_logo',
      url: MOTORWAY_URL,
    });
  },

  STICKY_VRM_BANNER_OLD_CTA_CLICK: ({ category, label, url } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'sticky_go',
      url,
    });
  },

  STICKY_VRM_BANNER_SELL_CLICK: ({ category, label = 'new' } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'sticky_sell',
      url: SELL_MY_CAR_PATH,
    });
  },

  // Sticky Widget events
  STICKY_VRM_WIDGET_CTA_CLICK: ({ category, label = null, url = null } : StickyCtaProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'widget_sell_car',
      url,
    });
  },

  STICKY_VRM_WIDGET_INPUT_FOCUS: ({ category, url = null } : StickyClickProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      name: 'widget_vrm_input',
      url,
    });
  },

  STICKY_VRM_WIDGET_LOADED: ({ category, label = 'new' } : StickyWidgetLoadedProps) => {
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category,
      label,
      name: 'widget',
    });
  },

  STICKY_VRM_WIDGET_OLD_CTA_CLICK: ({ category, label = null, url = null } : StickyCtaProps) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category,
      label,
      name: 'widget_value_car',
      url,
    });
  },

} as const;
