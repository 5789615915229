import { useHistory } from 'react-router';
import qs from 'query-string';

const useScrubQueryParam = (key) => {
  const history = useHistory();
  const queryParams = qs.parse(history.location.search);

  if (queryParams[key]) {
    try {
      const { [key]: value, ...otherParams } = queryParams;

      const newLocation = {
        ...history.location,
        search: qs.stringify(otherParams),
      };

      history.replace(newLocation);
      return value;
    } catch (e) {
      console.log('Allow stage when running CI to proceed'); // eslint-disable-line no-console
    }
  }
  return null;
};

export default useScrubQueryParam;
