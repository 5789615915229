export const GAEvent = (payload, cleanup) => {
  // Move this out of the event handler to avoid blocking the main thread and onto the next tick
  setTimeout(() => {
    window?.dataLayer?.push?.({
      eventAction: undefined,
      eventCategory: undefined,
      eventLabel: undefined,
      eventValue: undefined,
      ...payload,
      event: payload.event || 'UAEvent',
    });

    if (cleanup) { // https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { event, ...rest } = payload;

      window?.dataLayer?.push?.(
        Object.keys(rest)
          .reduce((acc, key) => ({
            ...acc, [key]: undefined,
          }), {}),
      );
    }
  }, 0);
};

export const snowplowEvent = ({ schema, version = '1-0-0', ...payload }) => {
  // Move this out of the event handler to avoid blocking the main thread and onto the next tick
  setTimeout(() => {
    window?.snowplow?.('trackSelfDescribingEvent', {
      event: {
        data: {},
        schema: `iglu:uk.co.motorway/${schema}/jsonschema/${version}`,
        ...payload,
      },
    });
  }, 0);
};

export const PAGES = Object.freeze({
  ACCEPT_OFFER: 'Accept offer',
  ACCOUNT: 'Account',
  COLLECTION_PREFERENCES: 'Collection preferences',
  CONFIRM_TIMEFRAME: 'Confirm timeframe',
  CONSENT: 'Consent',
  DOCUMENT_CAPTURE_BANK_DETAILS: 'Bank details',
  DOCUMENT_CAPTURE_UPLOAD: 'Documents upload',
  DOCUMENT_FRAMING: 'Document framing',
  ENQUIRY: 'Enquiry',
  ESTIMATED_SALE_PRICES_MODAL: 'Modal estimated sale prices',
  FOOTER: 'Footer',
  FORGOT_PASSWORD: 'Forgot password',
  HEADER: 'Header',
  HERO: 'Hero',
  HOME: 'homepage',
  LANDINGPAGE: 'Landing Page',
  MARKETPLACE: 'Marketplace valuation',
  MARKETPLACE_PROGRESS: 'Marketplace valuation (in progress)',
  MENTIONME: 'Mention Me',
  NONELIGIBLE: 'Non eligible',
  PREMIUM_VEHICLE_INFORMATION: 'Premium vehicle information',
  PROFILE: 'Profile page',
  PROOF_OF_ADDRESS: 'Proof of address',
  SALE_CONFIRMATION: 'Sale confirmation page',
  SERVICE_HISTORY: 'Service History',
  SIGN_IN: 'Sign in',
  SIGN_IN_VRM: 'Sign in (existing VRM)',
  SIGN_UP: 'Sign up',
  TIMELINE: 'Timeline',
  ULEZ: 'ULEZ Landing Page',
  ULEZ_RESULTS: 'ULEZ Results Page',
  UPDATED_VALUATION: 'Updated valuation',
  VALUATION_EXPIRED: 'Valuation expired',
  VALUATION_UNAVAILABLE: 'Valuation unavailable',
  VEHICLE_PAYMENT: 'Vehicle payment',
});

export const GA_EVENT_TYPES = Object.freeze({
  BUTTON: 'button',
  EMAIL: 'email',
  PAGE_LOADER: 'page loader - custom event',
  PHONE: 'telephone',
});

// TODO - When cleaning this up, we should remove them from GTM too
// ❌ - Not used in pre-fastlane code
// 🎯 - Now used in fastlane code
// ❓ - Not sure if needed
// 👴🏻 - Deprecated
// ✅ - Implemented

export const GA_TAGS = Object.freeze({
  // 'branch_map_viewed', // 👴🏻
  // 'competitor_click', // ❌
  // 'customer_details_changed', // 🎯 Old account and prem form code sets this
  // 'delayHistoryChange', // ❌
  // 'email_provider_clicked',  // 🎯 Set in `<EmailSent/>`
  // 'partner_clickthrough': () => {}, // ❌
  // 'partner_reviews_viewed': () => {}, // 👴🏻
  // 'phone_validation_failed', // ❌
  // 'sign_in': () => {}, // ❌
  // 'table_sorted', // 👴🏻
  // 'tooltip_opened', // ❌
  // 'vehicle_sold_feedback', // 🎯 Set in `<Feedback/>`

  ACCEPT_OFFER: () => GAEvent({
    eventAction: 'Accept offer button clicked',
    eventCategory: PAGES.ACCEPT_OFFER,
  }),

  ACCOUNT_ADD_VEHICLE_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Add Vehicle button clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_CONFIRM_SALE_BUTTON_CLICKED: (state) => GAEvent({
    eventAction: 'Confirm sale button clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: state,
  }),

  ACCOUNT_CONSENT_SUBMITTED: ({ toggled }) => GAEvent({
    eventAction: 'Consent changed',
    eventCategory: 'Account consent',
    eventLabel: toggled,
  }),

  ACCOUNT_EMAIL_INPUT_FOCUSSED: () => GAEvent({
    eventAction: 'Email input focussed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_EMAIL_OPT_IN_CLICKED: (type) => GAEvent({
    eventAction: 'Email Opt In clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: type,
  }),

  ACCOUNT_FIELD_VALIDATION_FAILED: (failedFields) => GAEvent({
    eventAction: 'Field validation failed',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: failedFields,
  }),

  ACCOUNT_INVALID_VRM_WARNING_DISPLAYED: () => GAEvent({
    eventAction: 'Invalid VRM warning displayed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_NAME_INPUT_FOCUSSED: () => GAEvent({
    eventAction: 'Name input focussed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_PAGE_DEALER_INFO: (state) => GAEvent({
    eventAction: 'Dealer Contact info',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: state,
  }),

  ACCOUNT_PHONE_NUMBER_FOCUSSED: () => GAEvent({
    eventAction: 'Phone Number focussed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_POSTCODE_INPUT_FOCUSSED: () => GAEvent({
    eventAction: 'Postcode input focussed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_REFER_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Refer button clicked',
    eventCategory: PAGES.MENTIONME,
    eventLabel: 'Account page refer CTA',
  }),

  ACCOUNT_REMOVE_VEHICLE_CANCEL_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Remove Vehicle Cancel button clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_REMOVE_VEHICLE_CONFIRM_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Remove Vehicle Confirm button clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_REMOVE_VEHICLE_X_CLICKED: () => GAEvent({
    eventAction: 'Remove Vehicle X clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_SAVE_CHANGES_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Save Changes button clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_SEE_VALUATION_BUTTON_CLICKED: (state) => GAEvent({
    eventAction: 'See Valuation button clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: state,
  }),

  ACCOUNT_SUBMISSION_VALIDATION_FAILED: () => GAEvent({
    eventAction: 'Submission validation failed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_TEXT_OPT_IN_CLICKED: (type) => GAEvent({
    eventAction: 'Text Opt In clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: type,
  }),

  ACCOUNT_VALUE_TRACKER_CTA_CLICKED: () => GAEvent({
    eventAction: 'Get new valuation or View value tracker clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: 'refresh valuation',
  }),

  ACCOUNT_VALUE_YOUR_CAR_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Value your car button clicked',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_VEHICLE_REMOVED: () => GAEvent({
    eventAction: 'Vehicle removed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_VIEW_SALE_STATUS_BUTTON_CLICKED: (state) => GAEvent({
    eventAction: 'View sale status button clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: state,
  }),

  ACCOUNT_VIEW_TIMELINE_PROGRESS: (state) => GAEvent({
    eventAction: 'View timeline progress button clicked',
    eventCategory: PAGES.ACCOUNT,
    eventLabel: state,
  }),

  ACCOUNT_VRM_INPUT_FOCUSSED: () => GAEvent({
    eventAction: 'VRM input focussed',
    eventCategory: PAGES.ACCOUNT,
  }),

  ACCOUNT_VRM_SUBMITTED_SUCCESSFULLY: () => GAEvent({
    eventAction: 'VRM submitted successfully',
    eventCategory: PAGES.ACCOUNT,
  }),

  AUTH_FORGOT_PASSWORD_CREATE_NEW_PASSWORD_CLICKED: () => GAEvent({
    eventAction: 'Create new password clicked',
    eventCategory: PAGES.FORGOT_PASSWORD,
    eventLabel: 'Create_new_password_clicked',
  }),

  AUTH_FORGOT_PASSWORD_PAGE_LOADED: () => GAEvent({
    eventAction: 'Loading the reset page',
    eventCategory: PAGES.FORGOT_PASSWORD,
    eventLabel: 'Reset_page_loaded',
  }),

  AUTH_FORGOT_PASSWORD_REDIRECT_TO_SIGN_IN_PAGE: () => GAEvent({
    eventAction: 'Redirect to sign in page',
    eventCategory: PAGES.FORGOT_PASSWORD,
    eventLabel: 'Redirect_to_sign_in_page',
  }),

  AUTH_FORGOT_PASSWORD_SEND_RESET_EMAIL_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Send reset email button clicked',
    eventCategory: PAGES.FORGOT_PASSWORD,
    eventLabel: 'Send_reset_email_button_clicked',
  }),

  AUTH_SIGN_IN: (isSignInRoute) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
    eventLabel: 'auth_check',
  }),

  AUTH_SIGN_IN_FORGOT_PASSWORD_LINK_CLICKED: () => GAEvent({
    eventAction: 'Forgot password link clicked',
    eventCategory: PAGES.SIGN_IN,
    eventLabel: 'Forgot_password_link_clicked',
  }),

  AUTH_SIGN_IN_NO_ACCOUNT: (isSignInRoute) => GAEvent({
    eventAction: 'No account error screen displayed',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
    eventLabel: 'auth_check',
  }),

  AUTH_SIGN_IN_NO_ACCOUNT_VRM: () => GAEvent({
    eventAction: 'Proceeded to Sign Up',
    eventCategory: PAGES.SIGN_IN_VRM,
    eventLabel: 'auth_check',
  }),

  AUTH_SIGN_IN_PASSWORD: (isSignInRoute) => GAEvent({
    eventAction: 'Sign in password field displayed',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
    eventLabel: 'password',
  }),

  AUTH_SIGN_IN_SENT: (isSignInRoute) => GAEvent({
    eventAction: 'Sign in link confirmation message displayed',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
    eventLabel: 'magic_link',
  }),

  AUTH_SIGN_UP: (name) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
    eventLabel: 'password',
  }),

  AUTH_SIGN_UP_CLICKED: (name) => GAEvent({
    eventAction: 'See valuation clicked',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
    eventLabel: 'password',
  }),

  AUTH_SIGN_UP_CONSENT_SUBMITTED: ({ toggled, variant }) => GAEvent({
    eventAction: 'Consent changed',
    eventCategory: 'Sign up consent',
    eventLabel: `${PAGES.CONSENT} ${toggled} ${variant} (password)`.trim(),
  }),

  AUTH_SIGN_UP_FAILED: (name) => GAEvent({
    eventAction: 'Form submitted unsuccessfully',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
    eventLabel: 'password',
  }),

  AUTH_SIGN_UP_SUBMITTED: (name) => GAEvent({
    eventAction: 'User signed up successfully',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
    eventLabel: 'password',
  }),

  BANK_DETAILS_OVERLAY_CONTINUE: () => GAEvent({
    eventAction: 'Continue overlay button clicked',
    eventCategory: PAGES.DOCUMENT_CAPTURE_BANK_DETAILS,
  }),

  BANK_DETAILS_OVERLAY_OPENED: () => GAEvent({
    eventAction: 'Continue overlay opened',
    eventCategory: PAGES.DOCUMENT_CAPTURE_BANK_DETAILS,
  }),

  CAROUSEL_CLICKED: (title, direction) => GAEvent({
    eventAction: `${title} carousel clicked`,
    eventLabel: direction,
  }),

  COLLECTION_PREFERENCES_DATE_CLICKED: (selected, label) => GAEvent({
    eventAction: selected ? 'Date chip selected' : 'Date chip deselected',
    eventCategory: PAGES.COLLECTION_PREFERENCES,
    eventLabel: label,
  }),

  COLLECTION_PREFERENCES_DATES_SUBMITTED: () => GAEvent({
    eventAction: 'Submit button clicked',
    eventCategory: PAGES.COLLECTION_PREFERENCES,
  }),

  COLLECTION_PREFERENCES_DATES_UNAVAILABLE: (dates) => GAEvent({
    eventAction: 'Unavailable dates',
    eventCategory: PAGES.COLLECTION_PREFERENCES,
    eventLabel: dates,
  }),

  COLLECTION_PREFERENCES_PAGE: () => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.COLLECTION_PREFERENCES,
  }),

  CONFIRM_SALE_BUTTON_CLICKED: (error = '') => GAEvent({
    eventAction: 'Confirm sale button clicked',
    eventCategory: PAGES.SALE_CONFIRMATION,
    eventLabel: error,
  }),

  CONFIRM_TIMEFRAME: () => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.CONFIRM_TIMEFRAME,
  }),

  CONFIRM_TIMEFRAME_SUBMITTED: () => GAEvent({
    eventAction: 'Form submitted successfully',
    eventCategory: PAGES.CONFIRM_TIMEFRAME,
  }),

  CONTACT_BUTTON_CLICKED: ({ eventAction, eventLabel }) => GAEvent({
    eventAction,
    eventLabel,
  }),

  CUSTOMER_CREATED: () => GAEvent({ event: 'customer_created' }),

  DEFAULT_GA_EVENT: (eventAction, eventCategory, eventLabel) => GAEvent({
    eventAction,
    eventCategory,
    eventLabel,
  }),

  DETAILS_SECTIONS_CONFIRMED: (label) => GAEvent({
    eventAction: 'Details was confirmed',
    eventLabel: label,
  }),

  DOCUMENT_FRAMING_ACCEPT: (kind, page) => GAEvent({
    eventAction: 'Accept photo button clicked',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
    eventValue: page,
  }),

  DOCUMENT_FRAMING_CAMERA_ERROR: (error) => GAEvent({
    eventAction: 'Camera error detected',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: error,
  }),

  DOCUMENT_FRAMING_CLOSE_BUTTON_CLICKED: (kind) => GAEvent({
    eventAction: 'Close framing button clicked',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
  }),

  DOCUMENT_FRAMING_OPEN: (kind, page) => GAEvent({
    eventAction: 'Framing opened',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
    eventValue: page,
  }),

  DOCUMENT_FRAMING_OPENED_ON_TAP_TO_RETRY_CLICK: (kind, page, reason = 'no reason provided') => GAEvent({
    eventAction: 'Framing opened on Tap to Retry',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: `${kind} (${reason})`,
    eventValue: page,
  }),

  DOCUMENT_FRAMING_OPENED_WITHOUT_DOCUMENT_GUIDE: (kind, page) => GAEvent({
    eventAction: 'Framing opened without document guide',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
    eventValue: page,
  }),

  DOCUMENT_FRAMING_PHOTO_BUTTON_CLICKED: (kind) => GAEvent({
    eventAction: 'Take photo button clicked',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
  }),

  DOCUMENT_FRAMING_REJECT: (kind, page) => GAEvent({
    eventAction: 'Reject photo button clicked',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
    eventValue: page,
  }),

  DOCUMENT_FRAMING_UPLOAD_BUTTON_CLICKED: (kind) => GAEvent({
    eventAction: 'Upload button clicked',
    eventCategory: PAGES.DOCUMENT_FRAMING,
    eventLabel: kind,
  }),

  DOCUMENTS_COMPLETE_SECTION_OPENED: (documentType) => GAEvent({
    eventAction: 'Complete documents section opened',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_EARLY_UPLOAD_CARD_CLICKED: (label) => GAEvent({
    eventAction: 'Document messaging card clicked',
    eventCategory: PAGES.TIMELINE,
    eventLabel: label,
  }),

  DOCUMENTS_PROMPT_MODAL_LINK_CLICKED: () => GAEvent({
    eventAction: 'What documents do I need? overlay opened',
    eventCategory: PAGES.TIMELINE,
  }),

  DOCUMENTS_PROMPT_MODAL_NO_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Was this helpful no button clicked',
    eventCategory: PAGES.TIMELINE,
    eventLabel: 'No',
  }),

  DOCUMENTS_PROMPT_MODAL_YES_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Was this helpful yes button clicked',
    eventCategory: PAGES.TIMELINE,
    eventLabel: 'Yes',
  }),

  DOCUMENTS_TOOLTIP_CLICKED: (label) => GAEvent({
    eventAction: 'Documents Tooltip clicked',
    eventLabel: label,
  }),

  DOCUMENTS_TOOLTIP_SHOWN: (label) => GAEvent({
    eventAction: 'Documents Tooltip shown',
    eventLabel: label,
  }),

  DOCUMENTS_UPLOAD_ADD_LATER_CLICKED: (documentType) => GAEvent({
    eventAction: 'Add these later button clicked',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_ADD_LATER_MODAL_CLICKED: (documentType) => GAEvent({
    eventAction: 'Add later button clicked inside the upload modal',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_BUTTON_CLICKED: (documentType) => GAEvent({
    eventAction: 'Document upload button clicked',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_DOCUMENTS_ADDED: (documentType) => GAEvent({
    eventAction: 'Documents have been added',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_EXAMPLE_OVERLAY_OPENED: (title) => GAEvent({
    eventAction: 'Document example overlay opened',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: title,
  }),

  DOCUMENTS_UPLOAD_HELP_OVERLAY_CLICKED: (documentType) => GAEvent({
    eventAction: 'How do I fix this? overlay opened',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_PRIVACY_INFORMATION_LINK_CLICKED: (documentType) => GAEvent({
    eventAction: 'Privacy information link clicked',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: documentType,
  }),

  DOCUMENTS_UPLOAD_SUMMARY_TILE_CLICKED: (label) => GAEvent({
    eventAction: 'Summary tile clicked',
    eventCategory: PAGES.DOCUMENT_CAPTURE_UPLOAD,
    eventLabel: label,
  }),

  FOOTER_DEALER_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Dealer button clicked',
    eventCategory: PAGES.FOOTER,
  }),

  FOOTER_PRESS_LOGO_CLICKED: () => GAEvent({
    eventAction: 'Press logo clicked',
    eventCategory: PAGES.FOOTER,
  }),

  FOOTER_SOCIAL_LINK_CLICKED: () => GAEvent({
    eventAction: 'Social link clicked',
    eventCategory: PAGES.FOOTER,
  }),

  FOOTER_VRM_INPUT_FOCUSED: () => GAEvent({
    eventAction: 'VRM input focussed',
    eventCategory: PAGES.FOOTER,
  }),

  HEADER_ACCOUNT_CLICKED: () => GAEvent({
    eventAction: 'Account clicked',
    eventCategory: PAGES.HEADER,
  }),

  HEADER_HELP_LINK_CLICKED: () => GAEvent({
    eventAction: 'Help link clicked',
    eventCategory: PAGES.HEADER,
    eventLabel: 'help',
  }),

  HEADER_USERNAME_CLICKED: () => GAEvent({
    eventAction: 'User name clicked',
    eventCategory: PAGES.HEADER,
  }),

  HEADER_VEHICLE_CLICKED: () => GAEvent({
    eventAction: 'Vehicle clicked',
    eventCategory: PAGES.HEADER,
  }),

  // NEW FASTLANE EVENTS

  HOMEPAGE: () => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.HOME,
  }),

  LANDINGPAGES_COMMON_QUESTION_CLICKED: (clickType) => GAEvent({
    eventAction: 'Common question clicked',
    eventCategory: PAGES.LANDINGPAGE,
    eventLabel: clickType,
  }),

  LANDINGPAGES_RELATED_CAR_GUIDE_CLICKED: (title) => GAEvent({
    eventAction: 'Related car guide clicked',
    eventCategory: PAGES.LANDINGPAGE,
    eventLabel: title,
  }),

  MARKETPLACE_PAGE: (inProgress, slug) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: (inProgress) ? PAGES.MARKETPLACE_PROGRESS : PAGES.MARKETPLACE,
    eventLabel: slug,
  }),

  MARKETPLACE_PROGRESS_BUTTON_CLICKED: (position = '') => GAEvent({
    eventAction: 'View timeline sale progress clicked',
    eventCategory: PAGES.MARKETPLACE,
    eventLabel: position,
  }),

  MARKETPLACE_TOOLTIP_OPENED: () => GAEvent({
    eventAction: 'Tooltip opened',
    eventCategory: PAGES.MARKETPLACE,
  }),

  NEW_NAV_TRACKER_LINK_CLICKED: () => GAEvent({
    eventAction: 'Value Tracker clicked',
    eventCategory: PAGES.HEADER,
  }),

  NEW_NAV_ULEZ_LINK_CLICKED: () => GAEvent({
    eventAction: 'ULEZ clicked',
    eventCategory: PAGES.HEADER,
  }),

  NONELIGIBLE_PAGE: (type) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.NONELIGIBLE,
    eventLabel: type,
  }),

  OFFER_SELECTED: () => GAEvent({ event: 'offer_selected' }),

  PAGE_TRANSITION_COMPLETE: () => GAEvent({ event: 'pageTransitionComplete' }),

  PREMIUM_VEHICLE_ASSUMPTIONS_MODAL: () => GAEvent({
    event: 'VirtualPageView',
    virtualPageTitle: 'Profile - Confirm proper using of vehicle',
    virtualPageURL: '/profile/modal-assumptions',
  }, true),

  PREMIUM_VEHICLE_INFORMATION: (action, label) => GAEvent({
    eventAction: action,
    eventCategory: PAGES.PREMIUM_VEHICLE_INFORMATION,
    eventLabel: label,
  }),

  PREMIUM_VEHICLE_MANDATORY_MODAL: (label, eventAction) => GAEvent({
    eventAction: GA_EVENT_TYPES.BUTTON || eventAction,
    eventCategory: PAGES.PREMIUM_VEHICLE_INFORMATION,
    eventLabel: label,
  }),

  PROFILING_WHATS_NEXT_ACCORDION_OPENED: (label) => GAEvent({
    eventAction: 'Whats next accordion opened',
    eventCategory: PAGES.PROFILE,
    eventLabel: label,
  }),

  PROFILING_WHATS_NEXT_MODAL_LINK_CLICKED: (label) => GAEvent({
    eventAction: 'Whats next modal opened',
    eventCategory: PAGES.PROFILE,
    eventLabel: label,
  }),

  PROOF_OF_ADDRESS_MISMATCH_INFOBOX_CLOSED: () => GAEvent({
    eventAction: 'Address mismatch infobox closed',
    eventCategory: PAGES.PROOF_OF_ADDRESS,
  }),

  PROOF_OF_PURCHASE_MODAL: () => GAEvent({
    event: 'VirtualPageView',
    virtualPageTitle: 'PoP - No proof of purchase',
    virtualPageURL: '/profile/ownership/proof-of-purchase/no-proof-of-purchase',
  }, true),

  REQUEST_NEW_SMS: () => GAEvent({
    eventAction: 'request new sms',
    eventCategory: 'Update phone number',
    eventLabel: 'Update phone number SMS not working',
  }),

  SEE_PROFILE_CLICKED: (page) => GAEvent({
    eventAction: 'Button clicked',
    eventCategory: `on ${page}`,
  }),

  SERVICE_HISTORY: (label, eventAction, enquiryID = '') =>
    GAEvent({
      eventAction: GA_EVENT_TYPES.BUTTON || eventAction,
      eventCategory: PAGES.SERVICE_HISTORY,
      eventLabel: `${label} ${enquiryID ? `enquiryID: ${enquiryID}` : ''}`,
    }),

  SIGN_IN: (isSignInRoute) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
  }),

  SIGN_IN_NO_ACCOUNT: (isSignInRoute) => GAEvent({
    eventAction: 'No account error screen displayed',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
  }),

  SIGN_IN_NO_ACCOUNT_VRM: () => GAEvent({
    eventAction: 'Proceeded to Sign Up',
    eventCategory: PAGES.SIGN_IN_VRM,
  }),

  SIGN_IN_SENT: (isSignInRoute) => GAEvent({
    eventAction: 'Sign in link confirmation message displayed',
    eventCategory: (isSignInRoute) ? PAGES.SIGN_IN : PAGES.SIGN_IN_VRM,
  }),

  SIGN_UP: (name) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
  }),

  SIGN_UP_CLICKED: (name) => GAEvent({
    eventAction: 'See valuation clicked',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
  }),

  SIGN_UP_CONSENT_SUBMITTED: ({ toggled }) => GAEvent({
    eventAction: 'Consent changed',
    eventCategory: 'Sign up consent',
    eventLabel: `${PAGES.CONSENT} ${toggled}`.trim(),
  }),

  SIGN_UP_FAILED: (name) => GAEvent({
    eventAction: 'Form submitted unsuccessfully',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
  }),

  SIGN_UP_SUBMITTED: (name) => GAEvent({
    eventAction: 'User signed up successfully',
    eventCategory: `${name} ${PAGES.SIGN_UP}`,
  }),

  STICKY_VRM_INPUT_FOCUSED: () => GAEvent({
    eventAction: 'VRM input focussed',
    eventLabel: 'Sticky VRM',
  }),

  TIMELINE: (eventLabel, eventAction) => GAEvent({
    eventAction: eventAction || GA_EVENT_TYPES.PAGE_LOADER,
    eventCategory: PAGES.TIMELINE,
    eventLabel,
  }),

  TIMELINE_BUTTON_CLICKED: (eventLabel, eventAction) => GAEvent({
    eventAction: eventAction || GA_EVENT_TYPES.BUTTON,
    eventCategory: PAGES.TIMELINE,
    eventLabel,
  }),

  TRUSTPILOT_CAROUSEL_CLICKED: (direction) => GAEvent({
    eventAction: 'Trustpilot carousel clicked',
    eventCategory: PAGES.MARKETPLACE,
    eventLabel: direction,
  }),

  ULEZ_ENQUIRY_SUBMISSION_FAILED: (error = '') => GAEvent({
    eventAction: 'VRM not submitted successfully',
    eventCategory: PAGES.ULEZ,
    eventLabel: error,
  }),

  ULEZ_ENQUIRY_SUBMITTED: (label) => GAEvent({
    eventAction: 'ULEZ Enquiry Submitted',
    eventCategory: PAGES.ULEZ,
    eventLabel: label,
  }),

  ULEZ_MAP_FULL_SCREEN: (label) => GAEvent({
    eventAction: 'ULEZ Map full screen',
    eventCategory: PAGES.ULEZ,
    eventLabel: label,
  }),

  ULEZ_MAP_LOADED: () => GAEvent({
    eventAction: 'ULEZ Map loaded',
    eventCategory: PAGES.ULEZ,
  }),

  ULEZ_VALUATION_SUBMITTED: (actionLabel, label) => GAEvent({
    eventAction: `ULEZ Valuation Submitted - ${actionLabel}`,
    eventCategory: PAGES.ULEZ_RESULTS,
    eventLabel: label,
  }),

  UPDATE_PHONE_NUMBER: () => GAEvent({
    eventAction: 'phone number',
    eventCategory: 'Update phone number',
    eventLabel: 'Update phone number SMS not working',
  }),

  V5C_LOGBOOK_MODAL: () => GAEvent({
    event: 'VirtualPageView',
    virtualPageTitle: 'V5C Logbook - Lost V5C logbook',
    virtualPageURL: '/profile/ownership/vehicle-v5c-logbook/lost-v5c-logbook',
  }, true),

  VALUATION_EXPIRED: () => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.VALUATION_EXPIRED,
  }),

  VALUATION_EXPIRED_SUBMITTED: () => GAEvent({
    eventAction: 'Form submitted successfully',
    eventCategory: PAGES.VALUATION_EXPIRED,
  }),

  VALUATION_UNAVAILABLE: (type) => GAEvent({
    eventAction: 'Page loaded',
    eventCategory: PAGES.VALUATION_UNAVAILABLE,
    eventLabel: type,
  }),
  VEHICLE_PAYMENT_ACCOUNT_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Account button clicked',
    eventCategory: PAGES.VEHICLE_PAYMENT,
    eventLabel: 'Account_button_clicked',
  }),
  VEHICLE_PAYMENT_BACK_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Back button clicked',
    eventCategory: PAGES.VEHICLE_PAYMENT,
    eventLabel: 'Back_button_clicked',
  }),
  VEHICLE_PAYMENT_CONTACT_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Contact button clicked',
    eventCategory: PAGES.VEHICLE_PAYMENT,
    eventLabel: 'Contact_button_clicked',
  }),
  VEHICLE_PAYMENT_CONTINUE_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Continue to payment button clicked',
    eventCategory: PAGES.VEHICLE_PAYMENT,
    eventLabel: 'Continue_to_payment_button_clicked',
  }),
  VEHICLE_PAYMENT_RECEIVE_PAYMENT_BUTTON_CLICKED: () => GAEvent({
    eventAction: 'Receive payment button clicked',
    eventCategory: PAGES.VEHICLE_PAYMENT,
    eventLabel: 'Receive_payment_button_clicked',
  }),
});
