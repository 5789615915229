import React from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import routing from 'Utilities/routing';

import { routes } from '../../routes';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { location: pathname } = this.props;

    const route = routing.getMatch(pathname, routes) || {};

    if (!route.manageOwnScroll && pathname !== prevLocation.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children || (null);
  }
}

ScrollToTop.defaultProps = {
  children: null,
};

ScrollToTop.propTypes = {
  children: PropTypes.node,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(ScrollToTop);
