/* eslint-disable max-len */

import { UserStateStatic } from 'Context/user';

import {
  gatewayPost,
  getRecentSalesProduction,
  getWordpressProduction,
  getWordpressStage,
  reviewsGet,
  webappGet,
  webappPostWithOrigin,
} from './fetch';
import { platformGet, platformPost } from './platformEndpoints';

// Urgh, if you destructure on initial import and if React hasn't constructed the context...
// ...then this will be the empty default.
// Since we only really call this from inside React Components if we destructure on call, we get what we need.
// TODO - Somehow make this consistent all the time
const getUser = () => {
  const { state } = UserStateStatic || {};

  return Number.isInteger(state?.id) ? state : {};
};

// Seller Webapp endpoints
export const getWordpress = async (slug, isTestingWpEngineAPI, headers) => (isTestingWpEngineAPI ? getWordpressStage(slug, headers) : getWordpressProduction(slug));
export const getAffiliate = async (affiliate) => webappGet('/api/affiliates', { affiliate });
export const getBrand = async (brand) => webappGet('/content/make', { make: brand });
export const getSitemap = async () => webappGet('/api/sitemap');
export const postSocialShare = async (payload) => webappPostWithOrigin('/social-share', payload);
export const postDisableTracker = async () => webappPostWithOrigin('/api/disable-tracker');

// Platform top level endpoints (https://github.com/motorway/motorway-platform)
export const postUlezCheck = async (vrm = '') => gatewayPost('/platform/v3/ulez/check', { vrm });
export const getCheckEligibility = (payload) => platformGet('/check-eligibility', payload);
export const postLoginEmail = async ({ deeplink, email = '', host = '' }) => platformPost('/login-email', { deeplink, email, host: host || window.location.origin });
export const unsubscribeCrmTier = async (payload) => platformPost('/v3/customers/unsubscribe', payload);
export const postVrmCheck = async (vrm = '') => {
  // `auth_token` will return an existing enquiry if a user has one
  const { auth_token, id } = getUser(); // eslint-disable-line camelcase

  return platformPost('/vrm-check', {
    user: { auth_token, id },
    vrm,
  });
};

// Reviews endpoints (https://github.com/motorway/motorway-reviews)
export const getReviews = async (slug, make = '') => reviewsGet('', { make, slug });

// Recent Sales endpoint
export const getRecentSales = async () => getRecentSalesProduction();
