import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { excludeMileagePath, replaceHyphensWithSpace } from 'Utilities/breadcrumbs';

import { formatURLToRemoveWP } from '../../contentPages/wordPressPages/utilities/WordpressHelpers';

import styles from './Breadcrumbs.scss';

type routeList = {
  label: string;
  route: string;
}[];

export type breadcrumbsProps = {
  readonly className?: string;
  readonly leftAlign?: boolean;
  readonly list?: routeList;
  readonly onYellow?: boolean;
};

const Breadcrumbs = ({
  className, leftAlign = false, list = [], onYellow = false,
}: breadcrumbsProps) => {
  const { pathname } = useLocation();

  const formattedPathname = formatURLToRemoveWP(pathname) === '/blog' ? 'blog' : pathname; // TODO - delete `formatURLToRemoveWP` after testing

  const links: routeList | [] = useMemo(() => {
    if (list.length) {
      return list;
    }

    const parts = excludeMileagePath(formattedPathname);

    if (parts.length > 1) {
      let prevPath = '';
      const crumbs = parts.map((it: string) => {
        prevPath += `/${it}`;
        return {
          label: replaceHyphensWithSpace(it),
          route: prevPath,
        };
      });
      crumbs.pop();
      crumbs.unshift({
        label: 'Home',
        route: '/',
      });

      return crumbs;
    }

    return [];
  }, [formattedPathname, list]);

  return (
    <nav className={cx(styles.crumbs, className, {
      [styles.leftAlign]: leftAlign,
      [styles.hidden]: links.length === 0,
    })}
    >
      {
        links.map(({ label, route }, i) => (
          <React.Fragment key={label}>
            <Link className={cx(styles.link, { [styles.onYellow]: onYellow })} to={route === '/sell-my-car' ? '/' : route }>
              <span className={styles.inner}>{label}</span>
            </Link>
            {
              (links.length - 1 !== i) ? (
                <span className={styles.divider}>/</span>
              ) : (null)
            }
          </React.Fragment>
        ))
      }
    </nav>
  );
};

export default Breadcrumbs;
