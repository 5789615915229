import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { classNamePropType } from 'Utilities/propTypes';

import styles from './layout.less';

const CONTENT_SIZES = Object.freeze({
  big: 'big',
  container: 'container',
  full: 'full',
  large: 'large',
  middle: 'middle',
  narrow: 'narrow',
  none: 'none',
  small: 'small',
});

const CONTENT_SIZES_ARR = Object.values(CONTENT_SIZES).map((val) => val);

const ComponentsWrapper = ({
  children, className = null, selector: Selector = 'div', ...props
}) => (
  <Selector {...props} className={cx(styles.wrapper, className)}>
    {children}
  </Selector>
);

ComponentsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: classNamePropType,
  selector: PropTypes.oneOf(['div', 'main', 'section']),
};

const ComponentContent = ({
  children, className, maxWidth, paddings = true, selector: Selector = 'div', ...props
}) => (
  <Selector {...props} className={
    cx(styles.content, styles[(maxWidth || CONTENT_SIZES.big)], className ?? null, { [styles.paddings]: paddings })
  }>
    {children}
  </Selector>
);

ComponentContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: classNamePropType,
  /**
   * Use `CONTENT_SIZES` to set one of the available sizes:
   *
   * - full - take whole place, no paddings
   * - container - max-width: 1040px
   * - big - max-width: 864px
   * - large - max-width: 544px
   * - middle - max-width: 480px
   * - small - max-width: 400px
   * - narrow - max-width: 336px
   * - none - no max-width, only paddings
   */
  maxWidth: PropTypes.oneOf(CONTENT_SIZES_ARR),
  paddings: PropTypes.bool,
  selector: PropTypes.oneOf(['article', 'aside', 'div', 'section']),
};

export { ComponentsWrapper, ComponentContent, CONTENT_SIZES };
