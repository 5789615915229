import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import { SP_FOOTER_EVENTS } from 'Utilities/analytics/analyticsFooter';
import { INSTANT_MATCH_URL } from 'Utilities/urls';

import { ReactComponent as TpLogo } from '../../../../../../client/assets_seller/trustpilot/trustpilot-logo-original-2022.svg';
import Texts from '../../../../../texts.json';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';

import styles from './FooterInstantMatchBrowse.module.scss';

const LocalT = new MDText(Texts.footerView.instantMatch);

const BROWSE_URL = `${INSTANT_MATCH_URL}/browse`;
const BROWSE_URL_WITH_UTM = `${BROWSE_URL}?utm_source=mw&utm_medium=footer_cta`;

export const FooterInstantMatchBrowse = ({ pathname }: { pathname: string }) => {
  const onVehicleLookupLoad = () => SP_FOOTER_EVENTS.FOOTER_INSTANT_MATCH_LOADED({ label: pathname });
  const { setRef } = usePageRefEvents({ onRefTrigger: onVehicleLookupLoad });

  return (
    <section ref={(ref) => setRef(ref)} className={styles.wrapper}>
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.column}>
            <h3 className={styles.title}>{LocalT.translate('title')}</h3>
            <p className="mw-i">{LocalT.translate('description')}</p>
          </div>
          <aside className={cx(styles.secondary, styles.column)}>
            <Button
              as="a"
              href={BROWSE_URL_WITH_UTM}
              icon="chevron"
              label={LocalT.translate('buttonLabel')}
              onClick={() => SP_FOOTER_EVENTS.FOOTER_INSTANT_MATCH_CTA_CLICKED({
                buttonLabel: `${LocalT.translate('buttonLabel')}`,
                label: pathname,
                url: BROWSE_URL,
              })}
              rel="noreferrer"
              size="large"
              target="_blank" />
            <figure className={styles.trustpilot}>
              <figcaption>{LocalT.translate('trustpilot')}</figcaption>
              <TpLogo />
            </figure>
          </aside>
        </div>
      </div>
    </section>
  );
};
