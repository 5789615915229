import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { vehiclePropType } from 'Utilities/propTypes';

import { ComponentContent, CONTENT_SIZES } from 'Layout';

import VrmInputMileage from './VrmInputMileage';
import VrmInputVRM from './VrmInputVRM';

import styles from './VrmInput.scss';

const VrmInput = ({
  analyticsEvents,
  inProgress,
  loadingHandler,
  maxWidth,
  setShowMileage,
  showMileage,
  vehicle: vehicleProp,
}) => {
  const { vehicleLookupState: lookupVehicle } = useContext(VehicleLookupContext);
  const vehicle = vehicleProp || lookupVehicle;

  const InputMode = (showMileage)
    ? VrmInputMileage
    : VrmInputVRM;

  const input = (
    <InputMode
      vrm={vehicle?.vrm || vehicle?.resultError?.vrm}
      {...{
        analyticsEvents,
        loadingHandler,
        setShowMileage,
        showMileage,
      }}
    />
  );

  return (
    <ComponentContent
      className={cx(styles.container, {
        [styles.fadeOut]: inProgress,
      })}
      maxWidth={maxWidth}
    >
      { input }
    </ComponentContent>
  );
};

VrmInput.defaultProps = {
  analyticsEvents: {},
  inProgress: false,
  loadingHandler: () => {},
  maxWidth: CONTENT_SIZES.narrow,
  setShowMileage: null,
  showMileage: false,
  vehicle: null,
};

VrmInput.propTypes = {
  analyticsEvents: PropTypes.shape(),
  inProgress: PropTypes.bool,
  loadingHandler: PropTypes.func,
  maxWidth: PropTypes.string,
  setShowMileage: PropTypes.func,
  showMileage: PropTypes.bool,
  vehicle: vehiclePropType,
};

export default withRouter(VrmInput);
