/* eslint-disable camelcase */

import { getCookieValue } from 'Utilities';
import { GuideData } from 'Utilities/guides/@types/guide';
import { User } from 'Utilities/user/@types';
import { WordpressGuideSummaryData } from 'Utilities/wordpress/@types';

// Note: Types should be moved to appropriate Utilities section when they are
// used by components.

type Affiliate = {
  createdAt: string;
  id: number;
  name: string;
  showBranding: boolean;
  slug: string;
  updatedAt: string;
}

type LandingPageData = {
  archive: null;
  content: any; // TODO: PIR-624 to create type
  meta: {
    desc: string;
    description: string;
    title: string;
  };
  posts: [];
  title: string;
}

type Brand = {
  createdAt: string;
  display_name: string;
  id: number;
  major: number;
  map_id: string;
  slug: string;
  updatedAt: string;
}

type BrandData = {
  brand?: Brand;
  brandContent?: {
    main: string;
  };
  brands?: Brand[];
}

type RecentSale = {
  sale: {
    price: number;
    soldDate: string;
  };
  seller: {
    firstName: string;
  };
  vehicle: {
    leadPhoto: {
      url: string;
    };
    make: string;
    model: string;
  };
}

type WordpressData = {
  archive: {
    label: string;
    route: string;
  }[] | null;
  content: string | null;
  meta: {
    desc: string;
    published?: string;
    title: string;
  };
  posts: {
    author: {
      // we don't need to know
      _links: any[];
      acf: [];
      avatar_urls: {
        [key: string]: string;
      }[];
      description: string;
      id: number;
      link: string;
      name: string;
      slug: string;
      url: string;
      yoast_head: string;
      yoast_head_json: any; // we don't need to know
    };
    content: string;
    date: string;
    excerpt: string;
    slug: string;
    title: string;
  }[];
  showFull: boolean;
  title: string | null;
}

export type InitialState = {
  affiliate?: Affiliate;
  appClipUrl: string;
  appName: string;
  appVersion: string;
  brandData: BrandData | null;
  customerVehicleLimit: {
    retail?: string;
    trade?: string;
  };
  experimentDecisions: Record<string, string>;
  featureFlags: {
    [key: string]: boolean;
  };
  gateway: string;
  guideData: GuideData | null;
  guidesPreviewData:WordpressGuideSummaryData[] | null;
  hideAnnouncement: boolean;
  isNewDesign: boolean | undefined;
  landingPageData?: LandingPageData;
  optimizelySdk: string;
  photoUploader: string;
  photosAppUrl: string;
  providersVersions?: string;
  recentSales: RecentSale[] | null;
  reviewsApi: string;
  reviewsData: {
    rating: string;
    totalReviews: string;
  };
  user: User | null;
  wordpressApi: string;
  wordpressData: WordpressData | null;
  youtubeEmbedURL: string;
};

export type InitialContext = {
  buyer: {
    name: string;
    slug: string;
  };
  failed: boolean;
}

export const getOptimizelyUserId = () => getCookieValue('Optimizely-User-Id');
