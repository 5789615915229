import { GENERIC_ANALYTICS_EVENTS } from 'Utilities/analytics/analyticsGenericEvents';

type marketingPreferencesProps = {
  isLoadInNewTab: boolean;
  isSuccess: boolean;
  url?: string;
};

type cvtModalCloseProps = {
  label: string;
  url: string;
};

type cvtPreferencesProps = {
  value: boolean;
  vrm: string;
};

export type accountAddVehicleStepProps = 'vrm' | 'mileage';

const categoryAccount = {
  category: 'account',
} as const;

const EVENT_CATEGORIES = {
  ADD_VEHICLE: 'add_vehicle',
  CVT_MODAL: 'cvt_confirm_mileage',
  REMOVE: 'remove_vehicle',
};

export const ACCOUNT_PAGE_SELL_MY_CAR_CTA_EVENTS = Object.freeze({
  ACCOUNT_CONFIRM_SALE_BUTTON_CLICKED: (name: string, url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name,
    url,
  }),
  ACCOUNT_PAGE_DEALER_INFO: (name: string, url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name,
    url,
  }),
  ACCOUNT_SEE_VALUATION_BUTTON_CLICKED: (name: string, url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name: name ?? 'start_selling',
    url,
  }),
  ACCOUNT_VIEW_SALE_STATUS_BUTTON_CLICKED: (name: string, url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name,
    url,
  }),
  ACCOUNT_VIEW_TIMELINE_PROGRESS: (name: string, url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name,
    url,
  }),
});

export const ACCOUNTS_PAGE_EVENTS = Object.freeze({
  ADD_VEHICLE_BUTTON_CLICKED: () => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      ...categoryAccount,
      name: EVENT_CATEGORIES.ADD_VEHICLE,
    });
    GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
      ...categoryAccount,
      name: EVENT_CATEGORIES.ADD_VEHICLE,
    });
  },
  ADD_VEHICLE_MILEAGE_SELL_CLICKED: (url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    ...categoryAccount,
    button_label: 'Start your profile',
    label: vrm,
    name: `${EVENT_CATEGORIES.ADD_VEHICLE}_popup_mileage_submit_smv`,
    url,
  }),
  ADD_VEHICLE_MILEAGE_TRACK_CLICKED: (url: string, vrm: string = 'N/A') => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    ...categoryAccount,
    button_label: 'Track value',
    label: vrm,
    name: `${EVENT_CATEGORIES.ADD_VEHICLE}_popup_mileage_submit_cvt`,
    url,
  }),
  ADD_VEHICLE_MODAL_CLOSED: (step: accountAddVehicleStepProps) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: `${step}_step`,
    name: `${EVENT_CATEGORIES.ADD_VEHICLE}_popup_close`,
  }),
  ADD_VEHICLE_VRM_SUBMIT_CLICKED: (vrm: string) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    ...categoryAccount,
    button_label: 'Continue',
    label: vrm,
    name: `${EVENT_CATEGORIES.ADD_VEHICLE}_popup_vrm_submit`,
    url: '/account',
  }),
  CVT_ALERTS_MODAL: (url: string = 'N/A') => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      ...categoryAccount,
      name: 'manage_cvt_preferences',
      url,
    });
    GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
      ...categoryAccount,
      name: 'cvt_preferences',
    });
  },
  CVT_ALERTS_MODAL_CLOSE: ({ label = 'N/A', url }: cvtModalCloseProps) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label,
    name: 'cvt_preferences_close',
    url,
  }),
  CVT_ALERTS_PREFERENCES: ({ value, vrm }: cvtPreferencesProps) => GENERIC_ANALYTICS_EVENTS.TOGGLE_CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name: 'cvt_preferences',
    value,
  }),
  CVT_CTA_CLICKED: (url: string, vrm?: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    name: 'track_value',
    url,
    ...(vrm && { label: vrm }),
  }),
  CVT_MILEAGE_MODAL_CLOSED: (vrm: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    label: vrm,
    name: `${EVENT_CATEGORIES.CVT_MODAL}_popup_close`,
  }),
  CVT_MILEAGE_MODAL_CONFIRM_BUTTON_CLICKED: (vrm: string, url: string) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    ...categoryAccount,
    button_label: 'Confirm mileage',
    label: vrm,
    name: `${EVENT_CATEGORIES.CVT_MODAL}_popup_submit`,
    url,
  }),
  CVT_MILEAGE_MODAL_SHOWN: (vrm: string) => GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
    ...categoryAccount,
    label: vrm,
    name: EVENT_CATEGORIES.CVT_MODAL,
  }),
  MARKETING_PREFERENCES: ({ isLoadInNewTab, isSuccess = false, url = 'N/A' }: marketingPreferencesProps) =>
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
      ...categoryAccount,
      button_label: 'Manage your email and SMS preferences',
      label: isLoadInNewTab ? 'new_tab' : 'same_tab',
      name: 'manage_marketing_preferences',
      success: isSuccess,
      url,
    }),
  REFERRAL_BANNER_CLICKED: (url: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    name: 'refer_friends',
    url,
  }),
  REMOVE_VEHICLE_CLICKED: (vrm?: string) => {
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      ...categoryAccount,
      name: EVENT_CATEGORIES.REMOVE,
      ...(vrm && { label: vrm }),
    });
    GENERIC_ANALYTICS_EVENTS.POPUP_LOAD_EVENT({
      ...categoryAccount,
      name: EVENT_CATEGORIES.REMOVE,
      ...(vrm && { label: vrm }),
    });
  },
  REMOVE_VEHICLE_CONFIRM_BUTTON_CLICKED: (vrm?: string) => GENERIC_ANALYTICS_EVENTS.CTA_CLICK({
    ...categoryAccount,
    name: `${EVENT_CATEGORIES.REMOVE}_confirm`,
    ...(vrm && { label: vrm }),
    button_label: 'Remove vehicle',
    url: '/account',
  }),
  REMOVE_VEHICLE_MODAL_CLOSED: (vrm?: string) => GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
    ...categoryAccount,
    name: `${EVENT_CATEGORIES.REMOVE}_popup_cancel`,
    ...(vrm && { label: vrm }),
  }),
  VEHICLE_REMOVED: (status: boolean, vrm?: string) => GENERIC_ANALYTICS_EVENTS.API_TRIGGERED({
    action: 'remove',
    backend_entity: vrm ?? 'N/A',
    backend_service: 'account_remove_vehicle',
    success: status,
  }),
});
