import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import Button from 'Button';

import sign from '../../../../client/assets_seller/sign.svg';
import Texts from '../../../texts.json';

import styles from './Error.less';

const LocalT = new MDText(Texts);

const Error = ({
  buttonLabel, buttonProps, detail, number, showSign, title,
}) => (
  <Content className={styles.view} element="section">
    { showSign && (
    <div className={styles.sign}>
      <img alt="sign" src={sign} />
      <div className={styles.asset}>
        <span className={styles.char}>M</span>
        <span className={styles.number}>{number}</span>
      </div>
    </div>
    ) }
    <h2 className={styles.title}>{LocalT.translate(title)}</h2>
    <p className={styles.detail}>{detail}</p>
    <Button {...buttonProps} className={styles.button} rightIcon="chevron-right">
      {LocalT.translate(buttonLabel)}
    </Button>
  </Content>
);

Error.defaultProps = {
  buttonProps: { element: Link, to: '/' },
  showSign: true,
};

Error.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape({ element: PropTypes.elementType, to: PropTypes.string }),
  // Temporary proptypes fix to stop it complaining.
  // In Error500 we pass a translation with multiple parts which comes through as an array with strings and React nodes.
  detail: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
    ),
  ]),
  number: PropTypes.string.isRequired,
  showSign: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Error;
