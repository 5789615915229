import { MDText } from 'i18n-react';

import { SocialFacebookIcon, SocialInstagramIcon, SocialXIcon } from '@motorway/mw-highway-code';

import { SITEMAP_HTML_URL } from 'Utilities/routing';
import { INSTANT_MATCH_URL } from 'Utilities/urls';
import { TAX_VALUATION_ROUTE } from 'Utilities/wordpress';

import appleAppStoreIcon from '../../../../../../client/assets_seller/appStoreIcons/icon-app-store.svg';
import googlePlayStoreIcon from '../../../../../../client/assets_seller/appStoreIcons/icon-play-store.svg';
import Texts from '../../../../../texts.json';

const LocalT = new MDText(Texts);

const texts = {
  ancillaryAbout: LocalT.translate('footerView.company.ancillaryPages.aboutPageTitle'),
  ancillaryBlog: LocalT.translate('footerView.company.ancillaryPages.blogPageTitle'),
  ancillaryCareers: LocalT.translate('jobsView.title'),
  ancillaryContact: LocalT.translate('footerView.company.ancillaryPages.contactPageTitle'),
  ancillaryCookies: LocalT.translate('footerView.company.ancillaryPages.cookiesPageTitle'),
  ancillaryHowItWorks: LocalT.translate('howItWorksView.title'),
  ancillaryPress: LocalT.translate('footerView.siteLinks.links.press'),

  ancillaryPrivacy: LocalT.translate('footerView.company.ancillaryPages.privacyPageTitle'),
  ancillaryRefer: LocalT.translate('referView.title'),
  ancillaryReviews: LocalT.translate('footerView.company.ancillaryPages.customerReviewsPageTitle'),
  ancillarySitemap: LocalT.translate('footerView.siteLinks.links.sitemap'),
  ancillaryStories: LocalT.translate('moreMoneyStoriesView'),
  ancillaryTerms: LocalT.translate('footerView.company.ancillaryPages.termsPageTitle'),

  ctaAppStoreDownload: LocalT.translate('footerView.cta.download.appStore'),
  ctaGooglePlayDownload: LocalT.translate('footerView.cta.download.googlePlay'),

  siteBuyYourNextCar: LocalT.translate('footerView.siteLinks.links.buyYourNextCar'),
  siteCarBuyers: LocalT.translate('footerView.siteLinks.links.carBuyers'),
  siteCarValueTracker: LocalT.translate('footerView.siteLinks.links.carValueTracker'),
  siteMotCheck: LocalT.translate('footerView.siteLinks.links.motCheck'),
  siteSellMyCar: LocalT.translate('footerView.siteLinks.links.sellMyCar'),
  siteSellMyCarFinance: LocalT.translate('footerView.siteLinks.links.sellMyCarFinance'),
  siteSellMyCarToDealer: LocalT.translate('footerView.siteLinks.links.sellMyCarToDealer'),
  siteSellMyVan: LocalT.translate('footerView.siteLinks.links.sellMyVan'),
  siteTaxCheck: LocalT.translate('footerView.siteLinks.links.taxCheck'),
  siteUlezCheck: LocalT.translate('footerView.siteLinks.links.ulezCheck'),

  socialFacebookLink: LocalT.translate('footerView.company.socialLinks.facebookLink'),
  socialFacebookTitle: LocalT.translate('footerView.company.socialLinks.facebookTitle'),
  socialInstagramLink: LocalT.translate('footerView.company.socialLinks.instagramLink'),
  socialInstagramTitle: LocalT.translate('footerView.company.socialLinks.instagramTitle'),
  socialTwitterLink: LocalT.translate('footerView.company.socialLinks.twitterLink'),
  socialTwitterTitle: LocalT.translate('footerView.company.socialLinks.twitterTitle'),
} as Record<string, string>;

export const pressRoutes = [
  { name: 'bbc', title: LocalT.translate('footerView.press.publications.publication3') },
  { name: 'daily-fail', title: LocalT.translate('footerView.press.publications.publication1') },
  { name: 'car-dealer', title: LocalT.translate('footerView.press.publications.publication5') },
  { name: 'the-guardian', title: LocalT.translate('footerView.press.publications.publication4') },
  { name: 'parkers', title: LocalT.translate('footerView.press.publications.publication2') },
  { name: 'tech-crunch', title: LocalT.translate('footerView.press.publications.publication6') },
];

export const socialRoutes = [
  {
    icon: SocialFacebookIcon,
    link: texts.socialFacebookLink,
    name: 'facebook',
    title: texts.socialFacebookTitle,
  },
  {
    icon: SocialXIcon,
    link: texts.socialTwitterLink,
    name: 'twitter',
    title: texts.socialTwitterTitle,
  },
  {
    icon: SocialInstagramIcon,
    link: texts.socialInstagramLink,
    name: 'instagram',
    title: texts.socialInstagramTitle,
  },
];

export const aboutRoutes = [
  {
    link: '/about',
    title: texts.ancillaryAbout,
  },
  {
    link: '/stories',
    title: texts.ancillaryStories,
  },
  {
    external: true,
    link: 'https://careers.motorway.co.uk/',
    title: texts.ancillaryCareers,
  },
  {
    link: '/press',
    title: texts.ancillaryPress,
  },
  {
    link: '/how-it-works',
    title: texts.ancillaryHowItWorks,
  },
  {
    link: '/reviews',
    title: texts.ancillaryReviews,
  },
  {
    link: '/refer',
    title: texts.ancillaryRefer,
  },
  {
    link: '/blog',
    title: texts.ancillaryBlog,
  },
  {
    external: true,
    link: 'https://help.motorway.co.uk/hc/en-gb/p/contact',
    title: texts.ancillaryContact,
  },
];

export const siteRoutes = [
  {
    link: '/',
    title: texts.siteSellMyCar,
  },
  {
    link: '/sell-my-van',
    title: texts.siteSellMyVan,
  },
  {
    link: '/sell-my-car/on-finance',
    title: texts.siteSellMyCarFinance,
  },
  {
    link: '/sell-my-car/to-a-dealer',
    title: texts.siteSellMyCarToDealer,
  },
  {
    link: '/sell-my-car/car-buyers',
    title: texts.siteCarBuyers,
  },
];

// ;kd,jd;dk;d;kd;md;dm;dml;dlmdldldlmlmklndmdkldmnmlkdlncghjhkl;'lkjhgknm,n.knlhlgjjknlnjhkvlnlghklkhvlknbjvhjk

export const toolsRoutes = [
  {
    link: '/car-value-tracker',
    title: texts.siteCarValueTracker,
  },
  {
    external: true,
    link: `${INSTANT_MATCH_URL}?utm_source=mw&utm_medium=footer_link`,
    title: texts.siteBuyYourNextCar,
  },
  {
    link: '/mot-check',
    title: texts.siteMotCheck,
  },
  {
    isNewRoute: true,
    link: TAX_VALUATION_ROUTE,
    title: texts.siteTaxCheck,
  },
  {
    link: '/ulez-checker',
    title: texts.siteUlezCheck,
  },
];

export const ancillaryRoutes = [{
  link: '/terms',
  title: texts.ancillaryTerms,
},
{
  link: '/privacy',
  title: texts.ancillaryPrivacy,
},
{
  link: '/cookies',
  title: texts.ancillaryCookies,
},
{
  link: SITEMAP_HTML_URL,
  title: texts.ancillarySitemap,
}];

export const applicationRoutes = [{
  description: texts.ctaAppStoreDownload,
  icon: {
    height: 51,
    src: appleAppStoreIcon,
    width: 152,
  },
  link: 'https://motorway.onelink.me/gYoi/tpq5xs67',
  name: 'apple store',
  title: 'Apple App Store',
},
{
  description: texts.ctaGooglePlayDownload,
  icon: {
    height: 46,
    src: googlePlayStoreIcon,
    width: 152,
  },
  link: 'https://motorway.onelink.me/gYoi/eam4fi09',
  name: 'google play store',
  title: 'Google Play Store',
}];
