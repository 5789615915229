/* eslint-disable camelcase */
/* eslint-disable max-len */

import {
  confirmationOfPayeePost,
  gatewayDelete,
  gatewayGet,
  gatewayPost,
  gatewayPut,
  LOGGING_LEVELS,
  LOGGING_OPTIONS,
  webappGetWithOrigin,
} from './fetch';
import { platformGet, platformPost } from './platformEndpoints';
import { userAPIErrorHandler } from './userAPI.helper';

// Seller Webapp endpoints
export const getUserLogOut = async () => webappGetWithOrigin('/api/logout');
export const getUserSession = async (payload) => webappGetWithOrigin('/api/session', payload);

// Platform top level endpoints (https://github.com/motorway/motorway-platform)
export const getUser = async (query) => platformGet('/user', query, { loggerLevel: LOGGING_LEVELS.ERROR }).catch((error) => userAPIErrorHandler(error));
export const getMotorwayOffer = async (vrm, valuation_ref) => platformGet('/premium-offer', { valuation_ref, vrm }, { ms: 10000 });
export const postEmailCheck = (email) => platformPost('/email-check', { email });
export const postCheckoutOffer = async (payload) => platformPost('/checkout-offer', payload);
export const postCreateEnquiry = async (payload, query) => platformPost(`/create-enquiry?${new URLSearchParams(query).toString()}`, payload);
export const postManageConsents = async (payload) => platformPost('/manage-consents', payload);
export const postRemoveVehicle = async (payload) => platformPost('/remove-vehicle', payload);
export const postUserUpdateInfo = async (payload) => platformPost('/update-info', payload);

// Platform v3 users endpoints
export const postCreateNewUser = async (payload) => platformPost('/v3/users', payload);

// Platform v3 customers endpoints
export const generateBrazeSubscriptionPreferencesUrl = async (headers) => platformGet('/v3/customers/get-preference-centre-url', undefined, { headers });
export const updateCrmTier = async (payload) => platformPost('/v3/customers/update-crm-tier', payload);
export const postPasswordResetEmail = (username) => platformPost('/v3/customers/reset-password', { username });
export const postPasswordUpdate = (payload) => platformPost('/v3/customers/change-password', payload);
export const postRefreshTokens = async (payload) => platformPost('/v3/customers/refresh-tokens', payload);
export const postCognitoUserLogin = async (payload) => platformPost('/v3/customers/login', payload);
export const postConfirmCognitoPasswordReset = async (payload) => platformPost('/v3/customers/confirm-password-reset', payload);

// Platform v3 trackers endpoints
export const updateMileageForTracker = async (payload) => platformPost('/v3/trackers/track', payload);
export const updateSubscriptionAlertsForTracker = async (payload) => gatewayPut('/platform/v3/trackers', payload);
export const getTrackerList = async () => platformGet('/v3/trackers/list');

// Platform virtual-assistant endpoints
export const getZendeskTokenAndCreateSession = async () => platformGet('/virtual-assistant/auth');

// Dealership Platform endpoints (https://github.com/motorway/motorway-dealership-platform)
export const getOfferBids = async (enquiryId) => gatewayGet('/premium/bids/offers', { enquiryId });
export const postAcceptOffer = async (payload) => gatewayPost('/premium/bids/accept-offer', payload);
export const postDealershipUserUpdate = async (id, payload) => gatewayPost(`/premium/sellers/${id}/update-details`, payload);
export const getValuationStatus = async (id, abortController) => gatewayGet(`/premium/vehicles/${id}`, { scope: 'state' }, undefined, { abortController });
export const confirmTransaction = async (vehicleId) => gatewayPost(`/premium/vehicles/${vehicleId}/send-final-dealer-confirmation-email`);
export const deleteDealershipVehiclePhotos = async (imageId) => gatewayDelete('/premium/photos', { vehicleImageId: imageId });

// Dealership Platform v3 endpoints
export const getEnquiryPrep = async (enquiryId) => gatewayGet(`/premium-v3/enquiries/${enquiryId}/sellerPrep`);
export const getEnquiryData = async (enquiryId, payload, query = 'state,seller,vehicle,vehicleDetails') => gatewayGet(`/premium-v3/enquiries/${enquiryId}`, { ...payload, ...(query.length && { include: query }) });
export const getTimelineData = async (enquiryId) => gatewayGet(`/premium-v3/enquiries/${enquiryId}/timeline`);
export const getVehiclePhotos = async (enquiryId, payload = { includeDeleted: 'true' }) => gatewayGet(`/premium-v3/enquiries/${enquiryId}/vehicleImages`, { ...payload });
export const postSimilarSoldVehicles = async (payload) => gatewayPost('/premium-v3/enquiries/similarSoldVehicles', payload);
export const putEnquiryConfirmation = async (enquiryId, payload = {}) => gatewayPut(`/premium-v3/enquiries/${enquiryId}/confirm`, payload).then((res) => res.data);
export const updateEnquiryVehicleData = async (enquiryId, payload) => gatewayPost(`/premium-v3/enquiries/${enquiryId}/vehicleDetails/update`, payload);
export const getEnquiryMetadata = async (enquiryId) => gatewayGet(`/premium-v3/enquiries/${enquiryId}/metadata`);

// Dealership Composer endpoints (https://github.com/motorway/mw-dealership-composer)
export const getConfirmSaleStatus = async (enquiryId) => gatewayGet(`/dealership/enquiries/${enquiryId}/confirmation-info`, { documentKindSet: 'required' }, 10000);

// Vehicle Core endpoints (https://github.com/motorway/mw-vehicle-core)
export const getDocuments = (enquiryId) => gatewayGet('/vehicle/documents/state', { enquiryId });
export const deleteDocuments = (payload) => gatewayPost('/vehicle/documents/delete', payload);
export const submitDocuments = (payload, headers) => gatewayPost('/vehicle/documents/review/markAsSubmitted', payload, headers);
export const checkDocumentSharpness = (payload) => gatewayPost('/vehicle/documents/checkSharpness', payload);

// Vehicles endpoints (https://github.com/motorway/motorway-vehicles)
export const getInsightsForTracker = async (vrm, payload) => gatewayGet(`/vehicles/vehicles/${vrm}/insights`, payload);

// Bidding endpoints (https://github.com/motorway/motorway-bidding)
export const getCountByMake = async (make) => gatewayGet('/bidding/bids/dealer-interest-counts', { makeSlug: make });

// Location endpoints (https://github.com/motorway/mw-location-core)
export const getAddressSuggestion = async (input, { abortController }) => gatewayGet('/location/address/suggestions', { input }, undefined, { abortController, logging: [LOGGING_OPTIONS.LOG_PATHNAME] });
export const getAddress = async (udprn) => gatewayGet(`/location/address/${udprn}`);

// Payments endpoints (https://github.com/motorway/mw-payments)

const PAYMENTS_APIS_TIMEOUT = 20000;

export const getBeneficiary = async (reference, abortController) => gatewayGet(`/payments/beneficiaries/${reference}`, undefined, PAYMENTS_APIS_TIMEOUT, { abortController });
export const postCOPCheck = async (payload) => confirmationOfPayeePost('/payments/beneficiaries/confirmation', payload);
export const updateBeneficiary = async (payload) => confirmationOfPayeePost('/payments/beneficiaries/update', payload);
export const getPostSaleOffers = async (enquiryId) => gatewayGet('/payments/postSaleOffers', { enquiryId }, PAYMENTS_APIS_TIMEOUT);
export const getLatestPostSaleOffer = async (enquiryId, query, abortController) => gatewayGet('/payments/postSaleOffers/latest', { enquiryId, ...query }, PAYMENTS_APIS_TIMEOUT, { abortController });
export const approvePostSaleOffers = async (enquiryId) => gatewayPost('/payments/postSaleOffers/approve', { enquiryId });
export const getPaymentsStatuses = async (enquiryId, abortController) => gatewayGet('/payments/payments/statuses', { enquiryId }, PAYMENTS_APIS_TIMEOUT, { abortController });
export const getPaymentsData = async (enquiryId, abortController) => gatewayGet('/payments/payments', { enquiryId }, PAYMENTS_APIS_TIMEOUT, { abortController });

// Photo Uploader endpoints (https://github.com/motorway/motorway-photo-uploader)
export const putPhotos = (photoUploader) => `${photoUploader}/api/v1/photos`;
export const putDocuments = (photoUploader) => `${photoUploader}/api/v1/documents`;

// Collection Preferences endpoints (https://github.com/motorway/mw-collection-preferences)
export const getPreferencesAvailableDates = () => gatewayGet('/collection-preferences/availability');
export const getPreferencesStatuses = (enquiryId) => gatewayGet(`/collection-preferences/seller-preferences/${enquiryId}`);
export const putCollectionPreferences = async (enquiryId, payload = {}) => gatewayPut(`/collection-preferences/seller-preferences/${enquiryId}`, payload);
