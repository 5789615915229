export const FLAG = {
  IM_GUIDES: 'im_guides',
  SKIP_EXPIRED_ENQUIRY: 'tc_skip_expired_enquiry',
  SOCIAL_VALIDATION_ON_AUTH: 'tc_social_validation_on_auth',
  ULEZ_ONWARD_JOURNEY: 'im_ulez_onward_journey',
} as const;

export const EVENT = {
  ENQUIRY_CREATED: 'enquiry_created',
  OFFER_SELECT: 'offer_select',
} as const;

export const EXPERIMENT_DECISIONS_HEADER = 'experiment-decisions';
