import { MDText } from 'i18n-react';

import { replaceHyphensWithSpace } from 'Utilities/breadcrumbs';

import { WORDPRESS_PATHS } from './WordpressConfig';
import Texts from './WordpressText.json';

const LocalT = new MDText(Texts);

export const formatURLToRemoveWP = (url) => url.replace(/\/wp/i, ''); // TODO : Delete after testing

export const fetchWordpressUrlSlug = (match) => {
  let { path, url } = match;
  let isGuideRoute = null;
  const { BLOGS, GUIDES } = WORDPRESS_PATHS;

  url = formatURLToRemoveWP(url); // TODO - remove after testing

  if (match?.params?.subdir) {
    isGuideRoute = `${GUIDES}/:subdir?`.match(match.path); // https://github.com/motorway/motorway-seller-webapp#wordpress-content
    url = match.params.subdir;
  }

  switch (path) {
    case GUIDES:
      url = 'guides';
      break;
    case BLOGS:
      url = 'blog';
      break;
    default:
      break;
  }

  return { isGuideRoute, url };
};

export const breadcrumbConfigurations = {
  blog: ({ dateConfigMap, post }) => {
    const {
      day, dayText, month, monthText, year,
    } = dateConfigMap;
    const { BLOG_ROUTE } = WORDPRESS_PATHS;

    return [
      {
        condition: year,
        navigation: {
          label: 'Blog',
          route: BLOG_ROUTE,
        },
        title: year,
      }, {
        condition: month,
        navigation: {
          label: year,
          route: `${BLOG_ROUTE}/${year}`,
        },
        title: monthText,
      }, {
        condition: day,
        navigation: {
          label: monthText,
          route: `${BLOG_ROUTE}/${year}/${month}`,
        },
        title: dayText,
      }, {
        condition: day && post,
        navigation: {
          label: dayText,
          route: `${BLOG_ROUTE}/${year}/${month}/${day}`,
        },
        title: null,
      },
    ];
  },
  guides: ({ path, subdir }) => {
    const { GUIDES } = WORDPRESS_PATHS;
    const normalPath = formatURLToRemoveWP(path); // TODO - delete after testing

    return [
      {
        condition: path === GUIDES || subdir,
        navigation: {
          label: replaceHyphensWithSpace(normalPath.split('/')[1]), // TODO - change to `path`
          route: `/${normalPath.split('/')[1]}`, // TODO - change to `path`
        },
      }, {
        condition: subdir && path === `${GUIDES}/:subdir?`,
        navigation: {
          label: LocalT.translate('guidesView.title'),
          route: GUIDES,
        },
      },
    ];
  },
};

// TODO - `formatPostURLS` & `formatBlogURLS` to be deleted after testing
export const formatPostURLS = (data) => {
  const DOM = document.createElement('div');
  DOM.innerHTML = data;
  DOM.querySelectorAll('a[href^=\'/sell-my-car/guides\']').forEach((link) => {
    link.href = link.href.replace(/sell-my-car\/guides/i, 'wp/sell-my-car/guides');
  });
  return DOM.innerHTML;
};
export const formatBlogURLS = (data) => {
  const posts = data?.posts;
  const archive = data?.archive;

  posts?.forEach((post) => {
    post.slug = post?.slug?.replace(/blog/i, 'wp/blog');
    post.excerpt = post?.excerpt?.replace(/blog/i, 'wp/blog');
  });

  archive?.forEach((slug) => {
    slug.route = slug?.route?.replace(/blog/i, 'wp/blog');
  });
  return { archive, posts };
};
