import React from 'react';
import { useField } from 'react-final-form';

import { MDText } from 'i18n-react';

import { Input } from '@motorway/motorway-storybook-cra';

import { coerceVRM, composeValidators, getFieldState, isValidVRM, requiredValidator, VRMValidator } from 'Utilities/formValidators';

import Texts from '../../../../../texts.json';

const LocalT = new MDText(Texts);

type VRMInputProps = {
  className?: string;
  e2eName?: string;
  large?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onInputFocus?: () => void;
  vrmId?: string;
};

export const VRMInput = ({
  className = undefined,
  e2eName,
  large = false,
  onChange = () => { },
  onInputBlur = () => { },
  onInputFocus = () => { },
  vrmId = '',
}: VRMInputProps) => {
  const { input, meta } = useField('vrm', {
    format: (value) =>
      (isValidVRM(value) ? coerceVRM(value) : value),
    initialValue: '',
    parse: (value) => value.toLocaleUpperCase(),
    validate: composeValidators(
      requiredValidator(
        LocalT.translate('components.vrmChecker.errorMessage'),
      ),
      VRMValidator(
        LocalT.translate('components.vrmChecker.errorMessage'),
      ),
    ),
  });

  const intent = getFieldState(meta, false);
  const id = `vrm-input${vrmId ? `-${vrmId}` : ''}`;

  return (
    <Input
      noIcon
      vrm
      className={ className }
      feedbackTooltip={ {
        content: meta.error,
        fillWidth: true,
        placement: 'top',
        zIndex: 10,
      } }
      inputProps={ {
        id,
        input: {
          ...input,
          autoCapitalize: 'characters',
          autoCorrect: 'off',
          'data-cy': 'vrmCheckers',
          'data-e2ename': e2eName,
          maxLength: 10,
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            input.onBlur(e);
            if (meta.invalid) {
              onInputBlur?.(e);
            }
          },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            input.onChange(e);
            onChange?.(e);
          },
          onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
            input.onFocus(e);
            onInputFocus?.();
          },
          placeholder: LocalT.translate('components.vrmChecker.placeholder'),
        },
      } }
      intent={intent}
      large={ large }
    />
  );
};
