import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { ULEZ_CHECKER_EVENTS } from 'Utilities/analytics';

import LocalTexts from '../../../containers/app/contentPages/ulezPage/ulezText.json';
import VrmErrors from '../../../containers/app/contentPages/ulezPage/VrmErrors/VrmErrors';
import VrmChecker from '../VrmChecker/VrmChecker';

import useHandleVRMUlezSubmit from './useHandleVRMUlezSubmit';

import styles from './VrmUlezChecker.scss';

const LocalT = new MDText(LocalTexts);

const VrmUlezChecker = ({ hideErrors, inFooter }) => {
  const gaEventLabel = inFooter ? 'ULEZ VRM Footer' : 'Hero';
  const snowplowEventLabel = inFooter ? 'footer' : 'hero';

  const [isValidVrm, setIsValidVrm] = useState(true);
  const [submittedVrm, setSubmittedVrm] = useState();

  const { disabled, errorMessage, handleVRMUlezSubmit } = useHandleVRMUlezSubmit({ gaEventLabel });

  const history = useHistory();
  const location = useLocation();

  const label = LocalT.translate(hideErrors ? 'vrmBox.widgetTitle' : 'vrmBox.title');
  const ctaLabel = LocalT.translate(isValidVrm ? 'vrmBox.buttonLabel' : 'vrmBox.error.buttonLabel');

  useEffect(() => {
    const { validVrm, vrm } = location?.state || {};

    if (validVrm !== undefined) {
      setIsValidVrm(validVrm);
      history.replace(location.pathname, null);
    }
    if (vrm) {
      setSubmittedVrm(vrm);
    }
  }, [location, history]);

  const vrmLabel = (
    inFooter
      ? <h4>{label}</h4>
      : <h3 className={styles.vrmLabel}>{label}</h3>
  );

  const content = (
    <>
      {
        isValidVrm || hideErrors
          ? vrmLabel
          : (
            <div className={styles.errorMessage}>
              <VrmErrors error={errorMessage} />
            </div>
          )
      }
      <VrmChecker
        chevron
        analyticsEvents={{
          onButtonClick: () => ULEZ_CHECKER_EVENTS.VRM_CTA_CLICKED(snowplowEventLabel, ctaLabel),
          onInputFocus: () => ULEZ_CHECKER_EVENTS.VRM_INPUT_FOCUSED(snowplowEventLabel),
        }}
        buttonProps={{
          className: cx(styles.ulezButton, 'mw-button-large'),
          label: ctaLabel,
        }}
        className="mw-input-vrm-large-white"
        data-cy="ulezVRM"
        disabled={disabled}
        handleVrmSubmit={handleVRMUlezSubmit}
        parentKey="ulez-vrm"
        value={submittedVrm}
      />
    </>
  );

  return (
    inFooter
      ? content
      : <aside className={cx(styles.vrmBox, { [styles.error]: !isValidVrm })}>{content}</aside>
  );
};

VrmUlezChecker.defaultProps = {
  hideErrors: false,
  inFooter: false,
};

VrmUlezChecker.propTypes = {
  hideErrors: PropTypes.bool,
  inFooter: PropTypes.bool,
};

export default VrmUlezChecker;
