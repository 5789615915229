import React from 'react';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import tpLogo from '../../../../../client/assets_seller/trustpilot/trustpilot-logo-new.svg';
import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';
import LocalTexts from '../trustpilotCommon/TrustpilotTexts.json';

import styles from './TrustpilotRatingBespoke.less';

const LocalT = new MDText(LocalTexts);

const TrustpilotRatingBespoke = ({ tpData }) => {
  const { maxWidth } = useBreakpoint();
  const mobileBreakpoint = maxWidth.breakpointMobile;

  const hrefProps = {
    href: 'https://uk.trustpilot.com/review/motorway.co.uk?utm_medium=trustbox&amp;utm_source=Slider',
    rel: 'noreferrer',
    target: '_blank',
  };

  return (
    <div className={styles.component}>
      <div className={styles.set1}>
        <div>
          {LocalT.translate('rated')}
          {' '}
          <strong>{tpData.rating}</strong>
          {' / 5'}
          &nbsp;
          {!mobileBreakpoint ? LocalT.translate('basedOn') : <strong>&#8226;</strong>}
          &nbsp;
          <a {...hrefProps}>
            <strong className={styles.total}>
              {tpData.totalReviews}
              {' '}
              {LocalT.translate('reviews')}
            </strong>
          </a>
          {!mobileBreakpoint ? '.' : ''}
          &nbsp;
        </div>
        <div>
          {LocalT.translate('5starReviews')}
        </div>
      </div>
      <div>
        <a className={styles.noBorder} {...hrefProps}>
          <img alt="Trustpilot" src={tpLogo} />
        </a>
      </div>
    </div>
  );
};

TrustpilotRatingBespoke.defaultProps = {
  tpData: {
    rating: null,
    totalReviews: null,
  },
};

TrustpilotRatingBespoke.propTypes = {
  tpData: PropTypes.shape({
    rating: PropTypes.string,
    totalReviews: PropTypes.string,
  }),
};

export default TrustpilotRatingBespoke;
