import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { GA_TAGS } from 'Utilities/analytics';
import {
  REVIEW_BODY_MAX_LENGTH,
  REVIEW_TITLE_MAX_LENGTH,
} from 'Utilities/helpers';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';
import useSwiperCarousel from '../swiper/useSwiperCarousel';

const TrustpilotCommonCarousel = ({
  brand,
  cardTitleTruncationLength,
  cardTruncationLength,
  generateBreakPointConfig,
  minReviews,
  PrevNextComponent,
  reviews,
  styles,
  TrustpilotCardComponent,
}) => {
  const [, setDomLoaded] = useState(false);

  const { maxWidth: breakpoints } = useBreakpoint();

  const $carousel = useRef();
  const $carouselNext = useRef();
  const $carouselPrev = useRef();

  const swiperConfig = generateBreakPointConfig(breakpoints);
  const reviewsLength = reviews?.length || 0;

  const enableSwiper = (reviewsLength > minReviews);
  const hideSwiperButtons = (reviewsLength <= swiperConfig?.slidesPerView);

  const swiperCarousel = useSwiperCarousel({
    config: {
      $el: $carousel.current,
      navigation: {
        nextEl: $carouselNext.current,
        prevEl: $carouselPrev.current,
      },
      on: {
        slideNextTransitionStart: () => {
          GA_TAGS.TRUSTPILOT_CAROUSEL_CLICKED('Next');
        },
        slidePrevTransitionStart: () => {
          GA_TAGS.TRUSTPILOT_CAROUSEL_CLICKED('Previous');
        },
      },
      ...swiperConfig,
    },
    enableSwiper,
  });

  useEffect(() => {
    setDomLoaded(true); // Trigger a mount re-render
  }, []);

  useEffect(() => {
    if (swiperCarousel && !swiperCarousel.destroyed) {
      const { params } = swiperCarousel;
      Object.assign(params, generateBreakPointConfig(breakpoints));
      swiperCarousel.update();
    }
  }, [breakpoints, generateBreakPointConfig, swiperCarousel]);

  useEffect(() => {
    if (swiperCarousel && !swiperCarousel.destroyed) {
      swiperCarousel.update();
    }
  }, [reviews, swiperCarousel]);

  // Can't add dynamic nav buttons on swiper v6, so have to init when ready with all the data!
  // https://github.com/nolimits4web/swiper/issues/4681
  const nextPrevClassNames = (vector) => cx(`swiper-button-${vector}`, styles[`${vector}Button`], { [styles.hide]: !enableSwiper || hideSwiperButtons });

  return (
    <div className={styles.component}>
      <div
        ref={$carousel}
        className={cx({
          swiper: enableSwiper,
          [styles.carousel]: enableSwiper,
          [styles.noCarousel]: !enableSwiper,
        })}
      >
        <div className={cx({
          [styles.noCarouselWrapper]: !enableSwiper,
          'swiper-wrapper': enableSwiper,
          [styles.wrapperCenter]: enableSwiper && hideSwiperButtons,
        })}
        >
          {
            reviews.map((review) => (
              <div key={review.timestamp} className={cx(styles.carouselItem, { 'swiper-slide': enableSwiper })}>
                <TrustpilotCardComponent {...{
                  brand,
                  cardTitleTruncationLength,
                  cardTruncationLength,
                  review,
                }}
                />
              </div>
            ))
          }
        </div>
      </div>
      <div ref={$carouselPrev} className={nextPrevClassNames('prev')}>
        <PrevNextComponent />
      </div>
      <div ref={$carouselNext} className={nextPrevClassNames('next')}>
        <PrevNextComponent />
      </div>
    </div>
  );
};

TrustpilotCommonCarousel.defaultProps = {
  brand: {
    display_name: null,
    slug: null,
  },
  cardTitleTruncationLength: REVIEW_TITLE_MAX_LENGTH,
  cardTruncationLength: REVIEW_BODY_MAX_LENGTH,
  minReviews: 4,
};

TrustpilotCommonCarousel.propTypes = {
  brand: PropTypes.shape({
    display_name: PropTypes.string,
    slug: PropTypes.string,
  }),
  cardTitleTruncationLength: PropTypes.number,
  cardTruncationLength: PropTypes.number,
  generateBreakPointConfig: PropTypes.func.isRequired,
  minReviews: PropTypes.number,
  PrevNextComponent: PropTypes.elementType.isRequired, // eslint-disable-line react/sort-prop-types
  reviews: PropTypes.arrayOf(PropTypes.shape).isRequired,
  styles: PropTypes.objectOf(PropTypes.string).isRequired,
  TrustpilotCardComponent: PropTypes.elementType.isRequired, // eslint-disable-line react/sort-prop-types
};

export default TrustpilotCommonCarousel;
